'use client';
import cx from 'classnames';
import React, { useRef } from 'react';
import {
  Autoplay,
  EffectCoverflow,
  Navigation,
  Pagination,
} from 'swiper/modules';
import {
  Swiper as SwiperComponent,
  SwiperClass,
  SwiperProps,
  SwiperSlide,
} from 'swiper/react';
import { PaginationOptions, SwiperOptions } from 'swiper/types';

interface ISwiper<T = any> {
  slidesData: T[];
  className?: string;
  pagination?: PaginationOptions | boolean;
  options?: SwiperOptions & SwiperProps;
  children?: React.ReactNode | any;
  customRenderSlide?: (slideItem?: T, index?: number) => React.ReactNode;
  customRender?: () => React.ReactNode;
  slideItemClass?: string;
}

type TSwiper = <T>(props: ISwiper<T>) => React.ReactElement;

const Swiper: TSwiper = ({
  children,
  slidesData = [],
  className = '',
  pagination = {},
  options = {},
  customRender = null,
  customRenderSlide = null,
  slideItemClass = '',
}) => {
  const swiperRef = useRef<SwiperClass | null>(null);

  const renderSlide = () => {
    return slidesData.map(
      typeof customRenderSlide === 'function'
        ? customRenderSlide
        : (slide, index) => (
            <SwiperSlide className={slideItemClass} key={index}>
              {typeof children === 'function'
                ? children(slide, index)
                : children}
            </SwiperSlide>
          )
    );
  };

  return (
    <SwiperComponent
      className={cx(className)}
      autoHeight={true}
      onSwiper={(swiper) => {
        swiperRef.current = swiper;
      }}
      modules={[Navigation, Pagination, Autoplay, EffectCoverflow]}
      initialSlide={0}
      slidesPerView={1}
      centeredSlides
      pagination={pagination}
      loop={true}
      {...options}
    >
      {typeof customRender === 'function' ? customRender() : renderSlide()}
    </SwiperComponent>
  );
};

export default Swiper;
