/* eslint-disable react-hooks/exhaustive-deps */
'use client';
import React, { useEffect } from 'react';
import CountUp from 'react-countup';

const CounterRef = ({ countUpRef, start }: any) => {
  useEffect(() => {
    start();
  }, []);

  return (
    <span className='text-11 inset-center flex flex min-w-fit items-center rounded-2xl font-bold'>
      <span className='text-main-pri'>
        <svg
          className='mb-[0.5px] mr-1 h-[12px] w-[12px]'
          xmlns='http://www.w3.org/2000/svg'
          width='8'
          height='8'
          viewBox='0 0 8 8'
          fill='none'
        >
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M4 8C6.20914 8 8 6.20914 8 4C8 1.79086 6.20914 0 4 0C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8ZM3.90005 7V6H2.7V2H3.90005V1H4.30005V2.00034C4.56708 2.00453 4.79132 2.04804 4.97278 2.13086C5.16392 2.2181 5.30759 2.33919 5.4038 2.49414C5.5 2.64779 5.5481 2.82487 5.5481 3.02539C5.5481 3.18164 5.51772 3.31901 5.45696 3.4375C5.3962 3.55469 5.31266 3.65104 5.20633 3.72656C5.10127 3.80078 4.98101 3.85352 4.84557 3.88477V3.92383C4.99367 3.93034 5.13228 3.97331 5.26139 4.05273C5.39177 4.13216 5.49747 4.24349 5.57848 4.38672C5.65949 4.52865 5.7 4.69792 5.7 4.89453C5.7 5.10677 5.64873 5.29622 5.5462 5.46289C5.44494 5.62826 5.29494 5.75911 5.0962 5.85547C4.89747 5.95182 4.65253 6 4.36139 6H4.30005V7H3.90005ZM4.69367 5.17383C4.60583 5.24895 4.47462 5.29266 4.30005 5.30497V4.24998C4.39461 4.25857 4.47835 4.28136 4.55127 4.31836C4.64747 4.36654 4.72152 4.43294 4.77342 4.51758C4.82532 4.60221 4.85127 4.69987 4.85127 4.81055C4.85127 4.96159 4.79873 5.08268 4.69367 5.17383ZM3.90005 5.30859V4.24609H3.52215V5.30859H3.90005ZM4.43165 3.61328C4.39058 3.63226 4.34671 3.64678 4.30005 3.65683V2.69562C4.4099 2.71425 4.49933 2.75451 4.56835 2.81641C4.66835 2.90495 4.71835 3.02148 4.71835 3.16602C4.71835 3.27148 4.69241 3.36198 4.64051 3.4375C4.58987 3.51302 4.52025 3.57161 4.43165 3.61328ZM3.90005 3.67383V2.68359H3.52215V3.67383H3.90005Z'
            fill='#E1C978'
          />
        </svg>
      </span>
      <span className='text-main-bg block' ref={countUpRef}></span>
    </span>
  );
};

const CounterUp: React.FC<any> = ({ start, Counter = null, ...props }) => {
  return (
    <CountUp
      scrollSpyOnce
      startOnMount
      duration={8}
      scrollSpyDelay={800}
      start={start}
      onEnd={({ start }) =>
        setTimeout(() => {
          start();
        }, 10000)
      }
      {...props}
    >
      {Counter || CounterRef}
    </CountUp>
  );
};

export default CounterUp;
