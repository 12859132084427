'use client';

import { useEffect, useState } from 'react';

import { isClient } from '@/lib/utils';

const getIsMobile = () => (isClient() ? window.innerWidth <= 768 : 0);

export default function useIsMobile() {
  const [isMobile, setIsMobile] = useState(getIsMobile());

  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return isMobile;
}
