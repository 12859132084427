/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
'use client';
import { Empty } from 'antd';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  getDataLocal,
  getParameterByName,
  saveLocalByKey,
  setDataLocal,
} from '@/lib/utils';

import { openGameProvider } from '@/components/core/OpenGame';
import { useAppRouter } from '@/components/hooks/useAppRouter';
import useIntersectionObserver from '@/components/hooks/useIntersectionObserver';
import CounterUp from '@/components/modules/CounterUp';
import PlaceHolderGameLoading from '@/components/modules/Loading/IndicatorLoading/PlaceHolderGameLoading';

import { useGameModalStore } from '@/store/zustand';

import { liveCasinoLobby } from '@/constant/gameLobby';

const Counter = ({ countUpRef, start }: any) => {
  useEffect(() => {
    start();
  }, []);

  return (
    <span className='vw:text-8 flex min-w-fit items-center rounded-2xl'>
      <img
        className='vw:mr-[2px] vw:w-[8px] vw:h-[8px]'
        src='/img/common/b.svg'
        alt='currency'
      />
      <span className='text-main-bg block' ref={countUpRef}></span>
    </span>
  );
};

export const hearted = 'HEARTED';
export const recentPlay = 'RECENT_PLAY';
export const lobby = 'lobby';

export const RenderCateItemGames = ({
  gameItems = [],
  slotJackpot = {},
  type = 'scroll',
  className,
  cateDetail,
  isNextLoading,
  pagination = {
    currentPage: 1,
    totalItems: 1,
    totalPages: 1,
  },
  fetchMoreData,
  partner,
}: any) => {
  const cate = getParameterByName('slug') || ('' as string);
  const { router } = useAppRouter();
  const [loaded, setLoaded] = useState(false);
  const [_, setHeartBasket] = useState(
    getDataLocal(hearted)?.[`${cate}`] || []
  );
  const { isLoadingGame } = useGameModalStore();
  const scrollWrapperRef = useRef(null);
  const sentinelRef = useRef(null);
  const hasNext = pagination?.totalPages > pagination?.currentPage;

  // const games = useMemo(
  //   () =>
  //     getDataLocal(hearted)?.[`${cate}`]?.length > 0 && cateDetail === 'all'
  //       ? sortByHearted(gameItems, getDataLocal(hearted)?.[`${cate}`])
  //       : gameItems,
  //   [gameItems, cateDetail]
  // );

  const isLocalCache =
    cateDetail === hearted || cateDetail === recentPlay || cateDetail === lobby;

  const gamesFilter: any = useMemo(() => {
    if (cateDetail === hearted) return getDataLocal(hearted)?.[`${cate}`];
    if (cateDetail === recentPlay) return getDataLocal(recentPlay)?.[`${cate}`];
    if (cateDetail === lobby)
      return !partner
        ? liveCasinoLobby
        : liveCasinoLobby.filter((item) => item.partner_provider === partner);

    // if (cate === 'casino' || cate === 'fishing' || cate === 'slots')
    //jackpot
    // return sortByHearted(
    // return sortByJackpot(gameItems, slotJackpot);
    // getDataLocal(hearted)?.[`${cate}`]

    // );

    return gameItems;
  }, [cateDetail, gameItems, cate]);

  useIntersectionObserver(
    sentinelRef,
    (entry) => {
      if (entry.isIntersecting && hasNext && !isNextLoading) {
        fetchMoreData();
      }
    },
    {
      threshold: 0.25,
    }
  );

  return (
    <>
      {!(gamesFilter?.length > 0) && (
        <>
          <div className='pt-6'>
            <Empty />
          </div>
        </>
      )}

      <div id='scrollableDiv'>
        <InfiniteScroll
          ref={scrollWrapperRef}
          scrollableTarget='scrollableDiv'
          className={clsx(
            type === 'scroll' &&
              'hide-scroll-bar -ml-3 snap-x snap-mandatory overflow-x-auto',
            'flex items-center gap-2 !overflow-hidden px-3',
            !hasNext && 'vw:pb-[90px]',
            className
          )}
          next={() => {}}
          dataLength={gameItems ? gameItems.length : 0}
          hasMore={hasNext}
          loader={<></>}
        >
          {gamesFilter?.map((_: any, index: number) => {
            const number = Number(
              slotJackpot?.[_?.partner_game_id] || _?.slotJackpot || 0
            );
            const end = Number(number || String(_?.id + '102321'));
            const start = end - 1000;

            return (
              <motion.div
                initial={{
                  opacity: 0,
                  y: 10,
                }}
                animate={{
                  opacity: 1,
                  y: 0,
                }}
                exit={{
                  opacity: 0,
                }}
                key={_.key || index}
                onClick={async () => {
                  if (isLoadingGame !== 0 && isLoadingGame !== _.id) return;
                  await openGameProvider(_, router, true);
                  const threeDaysInMillis = 3 * 24 * 60 * 60 * 1000; // 3 days in milliseconds
                  saveLocalByKey({
                    entryPointKey: recentPlay,
                    cate,
                    currentItem: _,
                    isRemoveable: false,
                    onSavedData: () => {
                      setDataLocal(recentPlay, {
                        ...(getDataLocal(recentPlay) || {}),
                        expiredTime: new Date().getTime() + threeDaysInMillis,
                      });
                    },
                    isCheckSaveDuplicate: true,
                  });
                }}
                className='relative mb-auto will-change-transform'
              >
                <div
                  className={clsx(
                    // 'shadow-box-light shadow-2 vw:max-h-[123px] relative w-full overflow-hidden rounded-[8px] border',
                    'vw:max-h-[123px] relative w-full overflow-hidden rounded-[8px] border',
                    !loaded && 'loading-bg'
                  )}
                >
                  <div
                    className={clsx(
                      'xs:vw:w-[25px] vw:w-[35px] xs:vw:h-[20px] vw:h-[30px] vw:left-[0] vw:top-0 xs:scale-[2.3] hide-tap-highlight absolute flex scale-[3] items-center justify-center overflow-hidden'
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      saveLocalByKey({
                        entryPointKey: hearted,
                        cate,
                        currentItem: _,
                        onRemovedData: (heartedStorageRemoved) => {
                          setHeartBasket(heartedStorageRemoved);
                        },
                        onSavedData: (heartedStorageSaved) => {
                          setHeartBasket(heartedStorageSaved);
                        },
                      });
                    }}
                  >
                    <div
                      className={clsx(
                        'vw:w-[20px] vw:h-[20px] heartContainer',
                        getDataLocal(hearted)?.[`${cate}`]?.find(
                          (h: any) => h?.id === _.id
                        ) && 'heartAnimated'
                      )}
                    />
                  </div>
                  <Image
                    loading={index > 10 ? 'lazy' : undefined}
                    priority={index <= 10}
                    onLoad={() => {
                      setLoaded(true);
                    }}
                    className={clsx(
                      'vw:max-h-auto xs:vw:max-h-[80px] w-full object-cover',
                      isLoadingGame === _.id && '!brightness-50'
                    )}
                    src={_.image || _.img}
                    key={_.img}
                    width={110}
                    height={80}
                    alt={_?.name || _?.label}
                  />
                  {isLoadingGame === _.id && (
                    <div className='absolute left-1/2 top-[20%] -translate-x-1/2'>
                      <img
                        className='mx-auto'
                        src='/img/common/loading.svg'
                        alt='loading'
                      />
                    </div>
                  )}
                </div>

                <div className='vw:mt-1 vw:xs:text-12 vw:text-14 vw:ml-0 line-clamp-1 text-ellipsis'>
                  {_?.name || _?.label}
                </div>
                {number > 0 && (
                  <div
                    className={clsx(
                      'vw:xs:ml-0 vw:ml-5 vw:xs:text-12 vw:text-14 vw:min-h-[20px] xs:scale-[1] mt-0.5 line-clamp-1 scale-[1.3] text-ellipsis'
                    )}
                  >
                    <div className='text-main-pri vw:px-0.5 vw:pr-1 vw:pt-[0px] relative w-fit rounded-full bg-[#000000b3]'>
                      <CounterUp
                        className='vw:text-8'
                        enableScrollSpy
                        duration={3.5}
                        startOnMount
                        start={start}
                        end={end}
                        Counter={Counter}
                      />
                    </div>
                  </div>
                )}
              </motion.div>
            );
          })}
        </InfiniteScroll>
        {!isLocalCache && hasNext && (
          <div
            className='vw:pb-[90px] pt:2 xs:grid-cols-3 grid grid-cols-2 gap-2 px-3'
            ref={sentinelRef}
          >
            <PlaceHolderGameLoading className='!aspect-[4/3.1]' amount={6} />
          </div>
        )}
      </div>
    </>
  );
};
