'use client';

import clsx from 'clsx';

import AnimatedTransition from '@/components/modules/AnimatedTransition';
import { TransitionAnimation } from '@/components/modules/AnimatedTransition/transitionConfig';

function Page({
  children,
  className,
  overlayBG = false,
  animation = TransitionAnimation.FADE,
  ...otherProps
}: any) {
  return (
    <AnimatedTransition
      {...otherProps}
      className={clsx('h-auto w-full will-change-auto', className, {
        'absolute left-0 top-0 h-full w-full bg-[rgba(0,0,0,0.75)]': overlayBG,
      })}
      animation={animation}
      onAnimationStart={null}
      onAnimationEnd={null}
    >
      {overlayBG && (
        <div className='absolute left-0 top-0 h-full w-full bg-[rgba(0,0,0,0.75)]' />
      )}
      {children}
    </AnimatedTransition>
  );
}

export default Page;
