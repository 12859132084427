'use client';
// import * as PIXI from 'pixi.js';
// import { BaseTexture, Texture } from 'pixi.js';

// Texture.addToCache = function (texture, id) {
//   if (id) {
//     if (texture.textureCacheIds.indexOf(id) === -1) {
//       texture.textureCacheIds.push(id);
//     }
//     PIXI.utils.TextureCache[id] = texture;
//   }
// };
// BaseTexture.addToCache = function (baseTexture, id) {
//   if (id) {
//     if (baseTexture.textureCacheIds.indexOf(id) === -1) {
//       baseTexture.textureCacheIds.push(id);
//     }
//     PIXI.utils.BaseTextureCache[id] = baseTexture;
//   }
// };

export const MAX_FPS = 60;

export const CENTERED_ANCHOR = 0.5;

export const baseScreenSize = {
  // base on iPhone 8 Plus
  WIDTH: 1440,
  HEIGHT: 1000,
};

export const maxScreenSizeRatio =
  typeof window !== 'undefined' ? window.devicePixelRatio || 1.5 : 1.5;

export const gameViewSize = {
  WIDTH:
    typeof window !== 'undefined'
      ? Math.min(window.innerWidth, window.innerHeight / maxScreenSizeRatio)
      : 0,
  HEIGHT: typeof window !== 'undefined' ? window.innerHeight : 0,
};
