/* eslint-disable react-hooks/rules-of-hooks */
import { getCookie } from 'cookies-next';

import { useTranslation } from '@/app/i18n';
import { fallbackLng } from '@/app/i18n/settings';

import i18n, { runsOnServerSide } from './client';

export const getTranslation = async (
  key: any,
  ns = 'default',
  options: any = {}
) => {
  if (runsOnServerSide) {
    const lang = getCookie('i18next') || fallbackLng;

    const { t } = await useTranslation(lang, ns, options);
    return t(key, options);
  }
  return i18n.t(key, options);
};
