/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-console */
import clsx, { ClassValue } from 'clsx';
import i18next from 'i18next';
import { groupBy, includes, mapValues, sortBy, uniq } from 'lodash-es';
import qs from 'qs';

import { getGames } from '@/lib/apis';
import { compareText } from '@/lib/helper';

import {
  hearted,
  recentPlay,
} from '@/app/[lng]/(home)/components/Mobile/components/Renderer/RenderCateItemGames';
import { categories } from '@/constant/categories';
import {
  PARTNER_PROVIDER,
  PARTNER_PROVIDER_OPTIONS,
  displayMapping,
  lobbyCateProvider,
} from '@/constant/data';
import { isLocal } from '@/constant/env';
import { baseScreenSize, gameViewSize } from '@/constant/gameConfig';
import { gameMenuDesktop } from '@/constant/gameMenu';

/** Merge classes with tailwind-merge with clsx full feature */
export function cn(...inputs: ClassValue[]) {
  return clsx(inputs);
}

export function isMobile(ua?: string | null) {
  const isServer = typeof window === 'undefined';
  let mobile: boolean;
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      ua || (!isServer && window?.navigator?.userAgent) || ''
    )
  ) {
    mobile = true;
    // true for mobile device
  } else {
    // false for not mobile device
    mobile = false;
  }
  return mobile;
}

export const getSearchParams = (
  searchParams = '',
  options?: {
    isObject?: boolean;
    isStringify?: boolean;
    encode?: boolean;
  }
) => {
  const { encode = false, isStringify = false } = options || {};
  if (typeof window !== 'undefined' && searchParams === '') {
    searchParams = window.location.search;
  }
  const searchParam = searchParams.replace('?', '');

  if (isStringify) {
    return qs.stringify(searchParam, {
      encode,
    });
  }

  return qs.parse(searchParam) as Record<string, any>;
};

export const isClient = () => typeof window !== 'undefined';

export const getWindow = () => {
  if (isClient()) {
    return window;
  }
  return null;
};

/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
export function getRandomArbitrary(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const formatThaiPhoneNumber = (phone = '', prefix = '+66') => {
  if (!phone) return '';

  const firstPhone = `${phone}`[0];
  // const phoneMaxLength = 10;
  // const isMaxLength = `${phone}`.length >= phoneMaxLength;

  // 0123456789 -> +66 1234566789
  if (firstPhone === '0') {
    return `${prefix}${`${phone}`.slice(1)}`;
  }
  // +66 && 123... -> +66 123...
  if (prefix && firstPhone !== '0') {
    return `${prefix}${phone}`;
  }

  if (prefix !== '' && firstPhone !== '0') {
    return `0${phone}`;
  }

  // if (firstPhone !== '0') {
  //   return `${prefix}${phone}`;
  // }

  return phone;
};

export const mapLabelFilterProvider = (
  name = '',
  options = { isFormat: false }
) => {
  // if (name === PARTNER_PROVIDER.mitek || name === PARTNER_PROVIDER.vingame) {
  //   return 'techplay';
  // }
  // if (name === PARTNER_PROVIDER.athena) {
  //   return 'saba';
  // }

  // if (
  //   name ===
  //   [PARTNER_PROVIDER.athena_keno, PARTNER_PROVIDER.athena_number].join(',')
  // ) {
  //   return 'ibc';
  // }

  // if (options?.isFormat && name?.split('_')?.length >= 2) {
  //   return name?.split('_')?.join(' ');
  // }

  // return name;
  let providerName = name;
  if (name === PARTNER_PROVIDER.mitek || name === PARTNER_PROVIDER.vingame) {
    providerName = 'techplay';
  }
  if (name === PARTNER_PROVIDER.athena) {
    providerName = 'saba';
  }

  if (
    name ===
    [PARTNER_PROVIDER.athena_keno, PARTNER_PROVIDER.athena_number].join(',')
  ) {
    providerName = 'ibc';
  }

  if (options?.isFormat && PARTNER_PROVIDER_OPTIONS?.[providerName]?.label) {
    return PARTNER_PROVIDER_OPTIONS?.[providerName]?.label;
  }

  return providerName;
};

export const mapValueFilterProvider = (name = '') => {
  if (name === 'ibc') {
    return [PARTNER_PROVIDER.athena_keno, PARTNER_PROVIDER.athena_number].join(
      ','
    );
  }

  if (
    decodeURIComponent(name) ===
    [PARTNER_PROVIDER.athena_keno, PARTNER_PROVIDER.athena_number].join(',')
  ) {
    return decodeURIComponent(name);
  }
  if (displayMapping?.[name]) return '';
  return name;
};

export const mapLotteryProvider = (name = '') => {
  if (
    name ===
      [PARTNER_PROVIDER.athena_keno, PARTNER_PROVIDER.athena_number].join(
        ','
      ) ||
    name === PARTNER_PROVIDER.athena_keno
  ) {
    return 'ibc';
  }
  return name;
};

export const isThaiLang = (str: string) => {
  if (!str) return false;

  return /([\u0E00-\u0E7F]+)/.test(str);
};

export const getParameterByName = (
  name: string,
  url = typeof window !== 'undefined' ? window.location.href : ''
) => {
  const nameStr = name.replace(/[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + nameStr + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const isDebug = () => {
  const debug = getParameterByName('debug');
  return !isLocal && debug === 'on';
};

export const getDataLocal = (key = '') => {
  if (typeof window !== 'undefined') {
    try {
      if (window.localStorage.getItem(key)) {
        const dataLocal = JSON.parse(window.localStorage.getItem(key) || '');
        return dataLocal;
      }
    } catch (error) {
      window.localStorage.removeItem(key);
      console.log('GetDataLocal error:', error);
    }
  }
};

export const setDataLocal = (
  key = '',
  val = {}
  // dataConcat = getDataLocal()
) => {
  if (typeof window !== 'undefined') {
    try {
      window.localStorage.setItem(key, JSON.stringify(val));
      return true;
    } catch (error) {
      console.log('SetDataLocal error:', error);
      return false;
    }
  }
};

export const clearLocal = (key = '') => {
  if (typeof window !== 'undefined') {
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      console.log('error:', error);
    }
  }
};

export const hotMapping = (value: string) => {
  if (value === 'hot' || value === 'all') return '';
  return decodeURIComponent(value);
};

export const cateMapping = (value: string) => {
  if (value === recentPlay || value === hearted || value === 'all') return '';
  return decodeURIComponent(value);
};

export const getOptionsCateDetail = (catesNumber: string[] = []) => {
  return catesNumber.map((num) => {
    return categories.find((_) => _.value === num);
  });
};

export function toVW(pixels = 0, defaultScreenWidth = 393, screenWidth = 100) {
  return `${(pixels * screenWidth) / defaultScreenWidth}vw`;
}

export function getKeyByValue(
  obj: Record<string, any>,
  value: any
): string | undefined {
  return Object.keys(obj).find((key) => obj[key] === value);
}

export const sortByHearted = (arr: any = [], heartedArr: any = []) => {
  const heartedMap = new Map(
    heartedArr.map((item: any, index: number) => [item.id, index])
  );

  return sortBy(arr, (item: any) => {
    const heartedIndex = heartedMap.get(item.id);
    return heartedIndex !== undefined
      ? heartedIndex
      : arr.length + arr.indexOf(item);
  });

  // return arr?.toSorted((x: any, y: any) => {
  //   const xIndex = heartedArr?.findIndex((_: any) => _?.id == x.id);
  //   const yIndex = heartedArr?.findIndex((_: any) => _?.id == y.id);

  //   if (xIndex !== -1 && yIndex !== -1) return xIndex - yIndex;
  //   if (xIndex !== -1) return -1;
  //   if (yIndex !== -1) return 1;
  //   return arr?.indexOf(x) - arr?.indexOf(y);
  // });
};

export const sortByJackpot = (
  arr: any = [],
  slotJackpot: any,
  isFilter = false
) => {
  if (slotJackpot && Object.keys(slotJackpot).length > 0) {
    try {
      const sortedJackpot = sortBy(
        arr?.filter((_?: any) => slotJackpot?.[_?.partner_game_id]),
        (x: any) => {
          return parseFloat(slotJackpot?.[x?.partner_game_id]) * -1;
        }
      );
      if (isFilter) return sortedJackpot;
      return sortedJackpot?.concat(
        arr?.filter((_?: any) => !slotJackpot?.[_?.partner_game_id])
      );
    } catch (error) {
      return arr;
    }
  }

  return arr;
};

export const truncate = (str = '', n = 10, useWordBoundary = true) => {
  if (str.length <= n) {
    return str;
  }
  const subString = str.slice(0, n - 1);
  return useWordBoundary
    ? subString.slice(0, subString.lastIndexOf(' ')) + '…'
    : subString;
};

export const findCateByName = (cateName = '') => {
  return categories.find(
    (cate) => cate.key === cateName || compareText(cate.key, cateName)
  );
};

export const findProviderByName = (cateName = '') => {
  return Object.values(PARTNER_PROVIDER).find((provider) =>
    compareText(provider, cateName)
  );
};

// export const scrollToTop = () => {
//   if ('scrollBehavior' in document.documentElement.style) {
//     // Use window.scrollTo with smooth behavior if supported
//     window.scrollTo({
//       top: 0,
//       behavior: 'smooth',
//     });
//   } else {
//     // Fallback for browsers that don't support smooth scrolling
//     if ('scrollTo' in window) {
//       window.scrollTo(0, 0); // Use window.scrollTo if available
//     } else if ('scrollTo' in document.body) {
//       document.body.scrollTo(0, 0); // Fallback to document.body.scrollTo
//     } else {
//       // Fallback to scrolling the body element to top directly
//       window.scrollTo(0, 0);
//     }
//   }
// };

export function toggleFullscreen(elem: any = document.documentElement) {
  if (!elem) return;
  if (
    !document.fullscreenElement &&
    // @ts-ignore
    !document.mozFullScreenElement &&
    // @ts-ignore
    !document.webkitFullscreenElement &&
    // @ts-ignore
    !document.msFullscreenElement
  ) {
    // Request fullscreen
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
      // @ts-ignore
    } else if (elem.msRequestFullscreen) {
      // @ts-ignore
      elem.msRequestFullscreen();
      // @ts-ignore
    } else if (elem.mozRequestFullScreen) {
      // @ts-ignore
      elem.mozRequestFullScreen();
      // @ts-ignore
    } else if (elem.webkitRequestFullscreen) {
      // @ts-ignore
      elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  } else {
    // Exit fullscreen
    if (document.exitFullscreen) {
      document.exitFullscreen();
      // @ts-ignore
    } else if (document.msExitFullscreen) {
      // @ts-ignore
      document.msExitFullscreen();
      // @ts-ignore
    } else if (document.mozCancelFullScreen) {
      // @ts-ignore
      document.mozCancelFullScreen();
      // @ts-ignore
    } else if (document.webkitExitFullscreen) {
      // @ts-ignore
      document.webkitExitFullscreen();
    }
  }
}

export const isWebGLSupported = () => {
  let supported, canvas;

  try {
    if (!canvas) canvas = document.createElement('canvas');
    console.log('canvas:', canvas);
    supported =
      !!window.WebGLRenderingContext &&
      (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'));
  } catch (e) {
    console.log(
      '%cWEBGL IS NOT SUPPORTED',
      'background: red; color: white;',
      'catch by webgl and experimental-webgl context check',
      e
    );
    supported = false;
  }

  if (canvas?.remove) canvas.remove();
  canvas = undefined;

  return supported;
};

export function toGVW(pixels = 0, screenWidth = window.innerWidth) {
  return (pixels * screenWidth) / baseScreenSize.WIDTH;
}

export function toGW(pixels = 0, gameViewWidth = gameViewSize.WIDTH) {
  console.log('gameViewWidth:', gameViewWidth);
  // return toGVW(pixels, gameViewWidth);
  return pixels;
}

export const transformParamsByProvider = (item: any) => {
  const params: any = {
    partner_provider: item.partner_provider,
    category_name: item?.partner_game_id || 'lobby',
    device: isMobile() ? 'mobile' : 'pc',
  };

  if (item.partner_game_type) {
    params.partner_game_type = item.partner_game_type;
  }

  switch (item?.partner_provider) {
    case PARTNER_PROVIDER.hacksaw:
      params.ru =
        window.location.pathname + window.location.search ||
        '/game/provider/hacksaw/3';
      params.language = i18next.language;
      break;

    case PARTNER_PROVIDER.evo_multi:
      params.table_id = item?.partner_game_id || 'lobby';
      break;

    case PARTNER_PROVIDER.nlc:
      params.table_id = item?.partner_game_id || 'lobby';
      break;

    case PARTNER_PROVIDER.btg:
      params.table_id = item?.partner_game_id || 'lobby';
      break;

    case PARTNER_PROVIDER.vivo_multi:
      params.table_id = item?.partner_game_id || 'lobby';
      params.category_name = item?.partner_game_type || '';
      break;

    case PARTNER_PROVIDER.ezugi:
      params.table_id = item?.partner_game_id || 'lobby';
      params.category_name = item?.partner_game_type || '';
      break;

    default:
      break;
  }

  return params;
};

export const updateQueryStringParameters = (
  uri: string,
  params: { [key: string]: string },
  onlyNonEmpty = false
) => {
  let updatedUri = uri;
  for (const [key, value] of Object.entries(params)) {
    if (onlyNonEmpty && !value) continue; // Skip if onlyNonEmpty is true and value is empty

    const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    if (updatedUri.slice(-1) === '?') {
      updatedUri = `${updatedUri}`.replace('?', '');
    }
    const separator = updatedUri.indexOf('?') !== -1 ? '&' : '?';
    if (updatedUri.match(re)) {
      updatedUri = updatedUri.replace(re, '$1' + key + '=' + value + '$2');
    } else {
      updatedUri += separator + key + '=' + value;
    }
  }
  return updatedUri;
};

export const saveLocalByKey = ({
  cate,
  currentItem,
  onRemovedData,
  onSavedData,
  isRemoveable = true,
  isCheckSaveDuplicate = false,
  entryPointKey = hearted,
}: {
  cate?: string;
  currentItem?: any;
  onRemovedData?: (data?: any) => void;
  onSavedData?: (data?: any) => void;
  isRemoveable?: boolean;
  isCheckSaveDuplicate?: boolean;
  entryPointKey?: string;
}) => {
  const heartedStorage = getDataLocal(entryPointKey)?.[`${cate}`] || [];

  const isHeartedItem = heartedStorage?.find(
    (h: any) => currentItem.id === h?.id
  );
  if (isHeartedItem && isRemoveable) {
    const heartedStorageRemoved = heartedStorage.filter(
      (h: any) => currentItem.id !== h?.id
    );

    const basketItems: any = {
      ...(getDataLocal(entryPointKey) || {}),
      [`${cate}`]: heartedStorageRemoved,
    };

    setDataLocal(entryPointKey, basketItems);
    if (onRemovedData && typeof onRemovedData === 'function')
      onRemovedData(heartedStorageRemoved);
    // setHeartBasket(heartedStorageRemoved);
    return;
  }

  const data =
    isHeartedItem && isCheckSaveDuplicate
      ? getDataLocal(entryPointKey)?.[`${cate}`]?.filter(Boolean)
      : [currentItem]
          .concat(getDataLocal(entryPointKey)?.[`${cate}`])
          ?.filter(Boolean);

  const basketItems: any = {
    ...(getDataLocal(entryPointKey) || {}),
    [`${cate}`]: data,
  };
  // @ts-ignore
  setDataLocal(entryPointKey, basketItems);
  if (onSavedData && typeof onSavedData === 'function') onSavedData(data);
};

export const getProvidersActive = async () => {
  try {
    const menuProvider = Object.keys(lobbyCateProvider).map((key) => {
      const item = lobbyCateProvider?.[key];
      return item?.partner_provider?.map((partner: any) => {
        return getGames({
          display: key,
          partner,

          limit: 1,
        });
      });
    });
    const menuProviderResp = await Promise.all(menuProvider?.flat());
    const menuProviderMap = menuProviderResp.map((i) => i?.data?.items)?.flat();
    const flattenedData = menuProviderMap?.flatMap((item) =>
      item?.display_types?.map((type: any) => ({
        type,
        provider: item?.partner_provider,
      }))
    );
    const grouped = groupBy(flattenedData, 'type');
    const providerByAPI = mapValues(grouped, (items) => ({
      partner_provider: uniq(items.map((item) => item?.provider)),
    }));
    return providerByAPI || lobbyCateProvider;
  } catch (error) {
    return lobbyCateProvider;
  }
};

export const filteredMenu = (providersActive: any = lobbyCateProvider) => {
  return gameMenuDesktop
    .map((menuItem: any) => {
      const filteredGames = menuItem.games.filter((game: any) => {
        if (game.isLobby === false) return menuItem;

        return includes(
          providersActive?.[menuItem?.display]?.partner_provider,
          game.partner_provider
        );
      });

      // Only include the menuItem if it has matching games
      if (filteredGames.length > 0) {
        return {
          ...menuItem,
          games: filteredGames,
        };
      }

      return null; // Filter out items with no matching games
    })
    .filter(Boolean) as any; // Remove null entries
};

export const getUniquePartnerProvidersWithSlugs = (lobbyCateProvider: any) => {
  const providerMap = new Map();

  Object.values(lobbyCateProvider).forEach((category: any) => {
    category.partner_provider.forEach((provider: any) => {
      // Tables not show
      if (!providerMap.has(provider) && category.lobbyCate !== 2) {
        providerMap.set(provider, category.slug);
      }
    });
  });

  return Array.from(providerMap.entries());
};

export const sortUniqueProvidersWithSlugs = (arr: any[] = []) => {
  const _arr = [...arr];
  const popularOrder = [
    'pgsoft',
    'evoplay', // Evolution
    'playngo',
    'pragmatic',
    'aesexy',
    'netent',
    'askmelotto', // AskMebet
  ];
  _arr.sort?.(([a]: any, [b]: any) => {
    const indexA = popularOrder.indexOf(a);
    const indexB = popularOrder.indexOf(b);

    if (indexA === -1 && indexB === -1) {
      return 0; // Neither A nor B are in the popular list, keep their order
    }
    if (indexA === -1) {
      return 1; // A is not in the popular list, so B comes first
    }
    if (indexB === -1) {
      return -1; // B is not in the popular list, so A comes first
    }
    return indexA - indexB; // Both are in the popular list, sort based
  });
  return _arr;
};
