import React from 'react';

import { TransitionAnimation } from '@/components/modules/AnimatedTransition/transitionConfig';
import { Page } from '@/components/modules/Page';

import { RenderCateItemGames } from '@/app/[lng]/(home)/components/Mobile/components/Renderer/RenderCateItemGames';
import { RenderProviderGames } from '@/app/[lng]/(home)/components/Mobile/components/Renderer/RenderProviderGames';

const KenoGames: React.FC = (props: any) => {
  return (
    <Page className='w-full' animation={TransitionAnimation.SLIDE_UP}>
      {props.games?.data?.items
        ? RenderCateItemGames({
            gameItems: props.games?.data?.items,
            pagination: props.games?.data?.meta,
            type: '',
            className: 'grid w-full grid-cols-2 xs:grid-cols-3 gap-2',
            ...props,
          })
        : RenderProviderGames(props?.items?.games, props?.setProvider)}
    </Page>
  );
};

export default KenoGames;
