export function getFromLocalStorage(key: string): string | null {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(key);
  }
  return null;
}

export function getFromSessionStorage(key: string): string | null {
  if (typeof sessionStorage !== 'undefined') {
    return sessionStorage.getItem(key);
  }
  return null;
}

export function buildLinkNews(data: any): string {
  return `/news/${data?.alias}/${data?.id}`;
}

export function formatNumber(x: number, separator = ',') {
  if (!x) {
    return 0;
  }
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  return parts.join('.');
}

export function formatNumberK(x: number, symbol = ' K', separator = ',') {
  if (!x) {
    return 0 + symbol;
  }
  if (x > 0 && x < 1000) {
    return x / 1000 + symbol;
  }
  const parts = (x / 1000).toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  return parts[0] + symbol;
}

export function removeAccent(str: string) {
  // remove accents
  const from =
      'àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ',
    to =
      'aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str?.replace(RegExp(from[i], 'gi'), to[i]);
  }

  if (!str) {
    return '';
  }

  str = str
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9-]/g, '-')
    .replace(/-+/g, '-');

  return str;
}

export function containsSpecialCharacters(str: string) {
  return /[ §`!@#$%^&*()_+\-=\]{};':"\\|,.<>?~àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ]/g.test(
    `${str}`.toLowerCase()
  );
}

export function containsSpecialCharactersWithoutSpace(str: string) {
  return /[§`!@#$%^&*()_+\-=\]{};':"\\|,.<>?~àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ]/g.test(
    `${str}`.toLowerCase()
  );
}

export function compareText(field: string, value: string) {
  return removeAccent(field).includes(removeAccent(value));
}
