'use client';
import clsx from 'clsx';
import React, { useRef } from 'react';

import Swiper from '@/components/core/Swiper';
import MatchCard from '@/components/modules/Slider/MatchSlider/components/MatchCard';

const MatchSlider: React.FC<any> = ({ data = [] }) => {
  const swiperRef = useRef<any>(null);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <div className='vw:h-[189px] relative m-auto -mb-6 w-full pb-2'>
      {data?.length > 1 && (
        <>
          <button
            className='!bg-linear-1 vw:w-[18px] vw:h-[18px] vw:left-[20px] vw:top-[75px] absolute z-20 flex items-center justify-center rounded-full active:translate-y-0.5'
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <svg
              className='cursor-hand vw:!h-[11px] vw:mr-0.5'
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='16'
              viewBox='0 0 6 9'
              fill='none'
            >
              <path
                d='M5.5 1L1 4.375L5.5 7.75'
                stroke='#424242'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </button>
          <button
            className='!bg-linear-1 vw:w-[18px] vw:h-[18px] vw:right-[20px] vw:top-[75px] absolute z-20 flex items-center justify-center rounded-full active:translate-y-0.5'
            onClick={() => swiperRef.current?.slideNext()}
          >
            <svg
              className='cursor-hand vw:!h-[11px] vw:ml-0.5 -scale-x-100'
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='16'
              viewBox='0 0 6 9'
              fill='none'
            >
              <path
                d='M5.5 1L1 4.375L5.5 7.75'
                stroke='#424242'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </button>
        </>
      )}
      <Swiper
        className={clsx(
          'vw:min-h-[182px]',
          data?.length === 1 && '!w-[92%]',
          data?.length === 2 && '!w-[92%]'
        )}
        options={{
          initialSlide: data?.length === 3 ? 1 : 0,
          wrapperClass: data?.length === 2 ? '!gap-2' : '',
          onBeforeInit: (swiper) => {
            swiperRef.current = swiper;
          },
          grabCursor: true,
          speed: 300,
          effect: data?.length > 2 ? 'coverflow' : '',
          pagination: {
            enabled: data?.length > 1,
            horizontalClass: 'matchSliderHorizontal',
            bulletActiveClass: 'matchSliderBulletActive',
            bulletClass: 'matchSliderBullet',
            clickable: true,
          },
          coverflowEffect: {
            rotate: 20, // Slide rotate in degrees
            depth: 250,
          },
          navigation: {
            enabled: data?.length > 1,
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          },
          loop: data?.length > 3,
          spaceBetween: data?.length === 2 ? 8 : data?.length === 1 ? 0 : 0,
          slidesPerView: data?.length > 2 ? 1.15 : 1,
          centeredSlides: data?.length > 1,
          // centeredSlidesBounds: true,
        }}
        slidesData={data}
        slideItemClass='vw:min-h-[172px] !mx-auto px-0'
      >
        {(slideItem: (typeof data)[0]) => {
          return <MatchCard cardItem={slideItem} />;
        }}
      </Swiper>
    </div>
  );
};

export default MatchSlider;
