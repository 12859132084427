'use client';

import clsx from 'clsx';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const defaultStyle: SweetAlertOptions = {
  customClass: {
    confirmButton:
      'flex flex-row items-center justify-center text-base font-bold px-2 h-12 rounded-xl bg-pri-main hover:bg-pri-lightest active:bg-pri-darkest text-pri-darkest w-full',
    cancelButton:
      'flex flex-row items-center justify-center text-base font-bold px-2 h-12 rounded-lg border-[1px] border-solid border-pri-lightest hover:border-pri-darkest active:border-pri-darkest text-pri-darkest text-sm w-full',
  },
  buttonsStyling: false,
};

class Notification {
  static sw = withReactContent(Swal);
  private static config: NonNullable<unknown> = defaultStyle;
  static fire(args: SweetAlertOptions): Promise<any> | Promise<void> {
    return this.sw.fire({ ...this.config, ...args });
  }
  static success(args: SweetAlertOptions) {
    this.sw.fire({
      timer: 6000,
      showConfirmButton: false,
      icon: 'success',
      heightAuto: false,
      ...this.config,
      ...args,
    });
  }

  static successV2(args: SweetAlertOptions) {
    this.sw.fire({
      timer: 6000,
      showConfirmButton: false,
      icon: 'success',
      heightAuto: false,
      ...this.config,
      ...args,
    });
  }

  static popup(
    args: SweetAlertOptions & {
      img?: React.ReactNode;
      description?: React.ReactNode;
      type?: 'info' | 'warning' | 'error' | 'success';
    }
  ) {
    const statusMap: any = {
      info: {
        icon: '/img/modal/info.png',
      },
      warning: {
        icon: '/img/modal/warning.png',
      },
      error: {
        icon: '/img/modal/error.png',
      },
      success: {
        icon: '/img/modal/success.png',
      },
    };

    return this.sw.fire({
      showCloseButton: true,
      closeButtonHtml: `<svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M6.7072 5.29289C6.31668 4.90237 5.68351 4.90237 5.29299 5.29289C4.90246 5.68342 4.90246 6.31658 5.29299 6.70711L10.5858 12L5.29289 17.2929C4.90237 17.6834 4.90237 18.3166 5.29289 18.7071C5.68342 19.0976 6.31658 19.0976 6.70711 18.7071L12 13.4142L17.293 18.7071C17.6835 19.0976 18.3167 19.0976 18.7072 18.7071C19.0977 18.3166 19.0977 17.6834 18.7072 17.2929L13.4143 12L18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289C18.3166 4.90237 17.6834 4.90237 17.2929 5.29289L12 10.5857L6.7072 5.29289Z'
        fill='#845D00'
      />
    </svg>`,
      html: (
        <div className='flex flex-col items-center justify-center'>
          <div
            className={clsx(
              args?.type === 'success' && 'shine',
              'min-h-[120px] max-w-[118px] pt-4'
            )}
          >
            {args.img || <img src={statusMap[args?.type || 'info']?.icon} />}
          </div>
          <span className='text-success-main mt-7 text-base'>
            {args.description}
          </span>
        </div>
      ),
      confirmButtonColor: '#D3A300',
      confirmButtonText: 'Confirm',
      heightAuto: false,
      ...this.config,
      ...args,
    });
  }
}

export default Notification;
