export const TransitionAnimation = {
  FADE: 'FADE',
  ZOOM: 'ZOOM',
  SLIDE_LEFT: 'SLIDE_LEFT',
  SLIDE_RIGHT: 'SLIDE_RIGHT',
  SLIDE_UP: 'SLIDE_UP',
  SLIDE_UP_2: 'SLIDE_UP_2',
  SLIDE_DOWN: 'SLIDE_DOWN',
};

export const TransitionAnimationConfig = {
  [TransitionAnimation.FADE]: {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    // transition: { ease: 'easeOut', duration: 1.5 },
  },
  [TransitionAnimation.ZOOM]: {
    initial: { opacity: 0, scale: 0.5, y: '-50vh' },
    animate: { opacity: 1, scale: 1, y: 0, x: 0 },
    exit: { opacity: 0, scale: 0.5, y: '-50vh' },
    transition: { ease: 'easeInOut' },
  },
  [TransitionAnimation.SLIDE_LEFT]: {
    initial: { x: '50vw', opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: { x: '50vw', opacity: 0 },
    transition: { ease: 'easeInOut' },
  },
  [TransitionAnimation.SLIDE_RIGHT]: {
    initial: { x: '-50vw', opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: { x: '-50vw', opacity: 0 },
    transition: { ease: 'easeInOut' },
  },
  [TransitionAnimation.SLIDE_UP]: {
    initial: { y: '7vh', opacity: 0.6, duration: 0.16 },
    animate: { y: 0, opacity: 1 },
    exit: { opacity: 0 },
    transition: { ease: 'easeOut', duration: 0.16 },
  },
  [TransitionAnimation.SLIDE_UP_2]: {
    initial: { y: '6vh', opacity: 0, duration: 0.2 },
    animate: { y: 0, opacity: 1 },
    exit: { y: '6vh', opacity: 0 },
    transition: { ease: 'easeOut', duration: 0.2 },
  },
  [TransitionAnimation.SLIDE_DOWN]: {
    initial: { y: '-50vh', opacity: 0 },
    animate: { y: 0, opacity: 1 },
    exit: { y: '-50vh', opacity: 0 },
    transition: { ease: 'easeInOut' },
  },
};
