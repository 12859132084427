import { useRouter as useNextRouter } from 'next/navigation';

import useClientTranslation from '@/components/hooks/useClientTranslation';

export const useAppRouter = () => {
  const nextRouter = useNextRouter();
  const { currentLink } = useClientTranslation('');

  const goBack = () => {
    if (window.history.length > 2) {
      nextRouter.back();
    } else {
      nextRouter.push('/');
    }
  };

  const router = {
    ...nextRouter,
    push: (path: string, options?: any) => {
      if (typeof path === 'string') {
        path = currentLink(`${path}`) as string;
      }
      nextRouter.push(path, options);
    },
    replace: (path: string, options?: any) => {
      if (typeof path === 'string') {
        path = currentLink(`${path}`) as string;
      }
      nextRouter.replace(path, options);
    },
  };

  return { router, goBack };
};
