export const TIME_JACKPOT = 20000;

export const PARTNER_PROVIDER = {
  // evo: 'evo',
  evo_multi: 'evo_multi',
  netent: 'netent',
  redtiger: 'redtiger',
  nlc: 'nlc',
  btg: 'btg',
  // vivo: 'vivo',
  vivo_multi: 'vivo_multi',
  vivosexy: 'vivosexy',
  hogaming: 'hogaming',
  ezugi: 'ezugi',
  sexy: 'sexy',
  aesexy: 'aesexy',
  // spribe: 'spribe',
  spribe_turbo: 'spribe_turbo',
  vingame: 'vingame',
  playngo: 'playngo',
  tomhorn: 'tomhorn',
  pragmatic: 'pragmatic',
  rik: 'rik',
  ktech: 'ktech',
  qtech: 'qtech',
  cq9: 'cq9',
  b52: 'b52',
  athena: 'athena',
  athena_keno: 'athena_keno',
  keno: 'keno',
  keno_sieu_toc: 'keno_sieu_toc',
  ksport: 'ksport',
  sportv: 'sportv',
  esport: 'esport',
  imsport: 'imsport',
  bti: 'bti',
  mui_cun: 'mui-cun',
  mitek: 'mitek',
  game_bai_hit: 'game_bai_hit',
  sagaming: 'sagaming',
  fachai: 'fachai',
  amb: 'amb',
  ibc: 'ibc',
  cockfight: 'cockfight',
  allbet: 'allbet',
  microgaming: 'microgaming',
  evoplay: 'evoplay',
  jdb: 'jdb',
  jili: 'jili',
  MG_SLOT: 'mg_slot',
  BETRADAR: 'betradar',
  viacasino: 'viacasino',
  pgsoft: 'pgsoft',
  go: 'go',
  dreamgaming: 'dreamgaming',
  askmelotto: 'askmelotto',
  athena_number: 'athena_number',
  hacksaw: 'hacksaw',
  nextspin: 'nextspin',
  kingmaker: 'kingmaker',
  simpleplay: 'simpleplay',
  sbo: 'sbo',
};

export const PARTNER_PROVIDER_OPTIONS: any = {
  // SPECIAL DUPLICATE SHOW
  'evo_multi_live-casino': { label: 'Evolution - Live' },
  evoplay_tables: { label: 'Evolution - Tables' },
  // SPECIAL DUPLICATE SHOW
  evo_multi: { label: 'Evolution' },
  netent: { label: 'Netent' },
  redtiger: { label: 'Red Tiger' },
  nlc: { label: 'NLC' },
  btg: { label: 'BTG' },
  vivo_multi: { label: 'Vivo Gaming' },
  vivosexy: { label: 'Vivosexy' },
  hogaming: { label: 'HoGaming' },
  ezugi: { label: 'Ezugi' },
  sexy: { label: 'Sexy' },
  aesexy: { label: 'AE Sexy' },
  spribe_turbo: { label: 'Spribe' },
  vingame: { label: 'Techplay' },
  playngo: { label: `Play'n GO` },
  tomhorn: { label: 'Tomhorn' },
  pragmatic: { label: 'Pragmatic' },
  rik: { label: 'Rik' },
  ktech: { label: 'Ktech' },
  qtech: { label: 'Qtech' },
  cq9: { label: 'cq9' },
  b52: { label: 'b52' },
  athena: { label: 'Athena' },
  athena_keno: { label: 'Athena Keno' },
  keno: { label: 'Keno' },
  keno_sieu_toc: { label: 'keno_sieu_toc' },
  ksport: { label: 'Ksport' },
  sportv: { label: 'Sportv' },
  esport: { label: 'Esport' },
  imsport: { label: 'Imsport' },
  bti: { label: 'BTI' },
  mui_cun: { label: 'mui_cun' },
  mitek: { label: 'Techplay' },
  game_bai_hit: { label: 'game_bai_hit' },
  sagaming: { label: 'SA Gaming' },
  fachai: { label: 'FaChai' },
  amb: { label: 'AskmeBet' },
  ibc: { label: 'iBC' },
  cockfight: { label: 'Cockfight' },
  allbet: { label: 'AllBet' },
  microgaming: { label: 'Microgaming' },
  evoplay: { label: 'Evoplay' },
  jdb: { label: 'JDB' },
  jili: { label: 'Jili' },
  MG_SLOT: { label: 'MG SLOT' },
  BETRADAR: { label: 'BETRADAR' },
  viacasino: { label: 'ViA Casino' },
  pgsoft: { label: 'PGSoft' },
  go: { label: 'Go' },
  dreamgaming: { label: 'Dream Gaming' },
  askmelotto: { label: 'AskmeLotto' },
  athena_number: { label: 'Athena Number' },
  hacksaw: { label: 'Hacksaw' },
  kingmaker: { label: 'Kingmidas' },
  nextspin: { label: 'Next Spin' },
  simpleplay: { label: 'SimplePlay' },
};

export const lobbyCateFilterOptions = [
  {
    label: 'All',
    value: PARTNER_PROVIDER.allbet,
  },
  {
    label: 'Pragmatic',
    value: PARTNER_PROVIDER.pragmatic,
  },
  {
    label: 'Micro Gaming',
    value: PARTNER_PROVIDER.microgaming,
  },
  {
    label: 'JDB',
    value: PARTNER_PROVIDER.jdb,
  },
  {
    label: 'PG Soft',
    value: PARTNER_PROVIDER.pgsoft,
  },
  {
    label: 'PlaynGo',
    value: PARTNER_PROVIDER.playngo,
  },
  {
    label: 'Fachai',
    value: PARTNER_PROVIDER.fachai,
  },
  {
    label: 'Jili',
    value: PARTNER_PROVIDER.jili,
  },
  {
    label: 'Amb',
    value: PARTNER_PROVIDER.amb,
  },
  {
    label: 'Askmelotto',
    value: PARTNER_PROVIDER.askmelotto,
  },
  {
    label: 'Mitek',
    value: PARTNER_PROVIDER.mitek,
  },
  {
    label: 'Spribe Turbo',
    value: PARTNER_PROVIDER.spribe_turbo,
  },
];

export const hotItems: any[] = [
  {
    key: 'techplay',
    icon: '/img/menu/slot/techplay.png',
    img: '/img/game/slot/banner_12.png',
    mobileImg: '/img/game/slot/banner_mb_techplay.png',
    partner_provider: PARTNER_PROVIDER.vingame,
    lobbyCate: '3',
    slug: 'slots',
  },
  {
    key: '3',
    icon: '/img/menu/live-casino/banner_7.png',
    img: '/img/game/live-casino/banner_7.png',
    mobileImg: '/img/game/live-casino/banner_mb_vivo.png',
    partner_provider: PARTNER_PROVIDER.vivo_multi,
    is_require_login: true,
  },
  {
    key: 'jili',
    icon: '/img/menu/slot/jili.png',
    img: '/img/game/slot/banner_4.png',
    mobileImg: '/img/game/slot/banner_mb_jili.png',
    partner_provider: PARTNER_PROVIDER.jili,
    lobbyCate: '2',
    slug: 'tables',
  },
  {
    key: '4',
    icon: '/img/menu/live-casino/banner_3.png',
    img: '/img/game/live-casino/banner_3.png',
    mobileImg: '/img/game/live-casino/banner_mb_sa.png',
    partner_provider: PARTNER_PROVIDER.sagaming,
    is_require_login: true,
  },
  {
    key: '5',
    icon: '/img/menu/live-casino/banner_4.png',
    img: '/img/game/live-casino/banner_4.png',
    mobileImg: '/img/game/live-casino/banner_mb_pragmatic.png',
    partner_provider: PARTNER_PROVIDER.pragmatic,
    is_require_login: true,
    partner_game_id: '104',
  },
  {
    id: 899,
    name: 'Gates of Olympus',
    image: 'https://phombet.com/static/2023/07/GATE-OF-OLYMPUS-192.png',
    image_feature: null,
    partner_provider: 'pragmatic',
    partner_game_id: 'vs20olympgate',
    is_new_tab: true,
    display_types: [3, 5],
    can_play: true,
  },
  {
    id: 787,
    name: 'Treasures of Aztec',
    image:
      'https://phombet.com/static/2023/04/pgsoft_87_Treasures-of-Aztec.png',
    image_feature: null,
    partner_provider: 'pgsoft',
    partner_game_id: '87',
    is_new_tab: true,
    display_types: [3, 5],
    can_play: true,
  },
  {
    id: 24,
    name: 'Zeus',
    image: 'https://phombet.com/static/2024/03/DINER-DELIGHTS.png',
    image_feature: null,
    partner_provider: 'amb',
    partner_game_id: '3019',
    is_new_tab: true,
    display_types: [3, 5],
    can_play: true,
  },
  {
    id: 774,
    name: 'Caishen Wins',
    image: 'https://phombet.com/static/2023/03/pgsoft_71_CAISHEN-WINS.png',
    image_feature: null,
    partner_provider: 'pgsoft',
    partner_game_id: '71',
    is_new_tab: true,
    display_types: [3, 5],
    can_play: true,
  },
  {
    id: 776,
    name: 'Ways of the Qilin',
    image:
      'https://phombet.com/static/2023/04/pgsoft_106_Ways-of-the-Qilin.png',
    image_feature: null,
    partner_provider: 'pgsoft',
    partner_game_id: '106',
    is_new_tab: true,
    display_types: [3, 5],
    can_play: true,
  },
  {
    id: 780,
    name: 'Lucky Neko',
    image: 'https://phombet.com/static/2023/04/pgsoft_89_lucky-neko.png',
    image_feature: null,
    partner_provider: 'pgsoft',
    partner_game_id: '89',
    is_new_tab: true,
    display_types: [3, 5],
    can_play: true,
  },
  {
    id: 782,
    name: 'Ganesha Gold',
    image: 'https://phombet.com/static/2023/10/ganesha-gold.png',
    image_feature: null,
    partner_provider: 'pgsoft',
    partner_game_id: '42',
    is_new_tab: true,
    is_require_login: true,
    partner_game_code: null,
    partner_game_type: null,
    url: '',
    display_types: [3],
    can_play: true,
  },
  {
    id: 788,
    name: 'Mahjong Ways',
    image: 'https://phombet.com/static/2023/04/pgsoft_65_mahjong1.png',
    image_feature: null,
    partner_provider: 'pgsoft',
    partner_game_id: '65',
    is_new_tab: true,
    display_types: [3, 5],
    can_play: true,
  },
];

export const liveCasinoItems: any[] = [
  {
    key: '1',
    icon: '/img/menu/live-casino/banner_1.png',
    img: '/img/game/live-casino/banner_1.png',
    mobileImg: '/img/game/live-casino/banner_mb_1.png',
    partner_provider: PARTNER_PROVIDER.viacasino,
    is_require_login: true,
  },
  {
    key: '2',
    icon: '/img/menu/live-casino/banner_2.png',
    img: '/img/game/live-casino/banner_2.png',
    mobileImg: '/img/game/live-casino/banner_mb_2.png',
    partner_provider: PARTNER_PROVIDER.allbet,
    lobbyCate: '6',
  },
  {
    key: '3',
    icon: '/img/menu/live-casino/banner_3.png',
    img: '/img/game/live-casino/banner_3.png',
    mobileImg: '/img/game/live-casino/banner_mb_3.png',
    partner_provider: PARTNER_PROVIDER.sagaming,
    is_require_login: true,
  },
  {
    key: '4',
    icon: '/img/menu/live-casino/banner_4.png',
    img: '/img/game/live-casino/banner_4.png',
    mobileImg: '/img/game/live-casino/banner_mb_4.png',
    partner_provider: PARTNER_PROVIDER.pragmatic,
    partner_game_id: '104',
    is_require_login: true,
  },
  {
    key: '5',
    icon: '/img/menu/live-casino/banner_5.png',
    img: '/img/game/live-casino/banner_5.png',
    mobileImg: '/img/game/live-casino/banner_mb_5.png',
    partner_provider: PARTNER_PROVIDER.ezugi,
    is_require_login: true,
  },
  {
    key: '7',
    icon: '/img/menu/live-casino/banner_7.png',
    img: '/img/game/live-casino/banner_7.png',
    mobileImg: '/img/game/live-casino/banner_mb_7.png',
    partner_provider: PARTNER_PROVIDER.vivo_multi,
    is_require_login: true,
  },
  {
    key: '8',
    icon: '/img/menu/live-casino/banner_8.png',
    img: '/img/game/live-casino/banner_8.png',
    mobileImg: '/img/game/live-casino/banner_mb_8.png',
    partner_provider: PARTNER_PROVIDER.evo_multi,
    is_require_login: true,
  },
  {
    key: '9',
    icon: '/img/menu/live-casino/banner_9.png',
    img: '/img/game/live-casino/banner_9.png',
    mobileImg: '/img/game/live-casino/banner_mb_9.png',
    partner_provider: PARTNER_PROVIDER.microgaming,
    partner_game_id: 'SMG_titaniumLiveGames_MP_Baccarat',
    is_require_login: true,
  },
  {
    key: '10',
    icon: '/img/menu/live-casino/banner_10.png',
    img: '/img/game/live-casino/banner_10.png',
    mobileImg: '/img/game/live-casino/banner_mb_10.png',
    partner_provider: PARTNER_PROVIDER.aesexy,
    is_require_login: true,
    lobbyCate: '6',
  },
  // {
  //   key: '11',
  //   icon: '/img/menu/live-casino/banner_dreamgaming.png',
  //   img: '/img/game/live-casino/banner_dreamgaming.png',
  //   mobileImg: '/img/game/live-casino/banner_mb_dreamgaming.png',
  //   partner_provider: PARTNER_PROVIDER.dreamgaming,
  //   is_require_login: true,
  //   // lobbyCate: '6',
  //   isLobby: false,
  // },
  {
    key: '12',
    icon: '/img/menu/live-casino/simpleplay.png',
    img: '/img/game/live-casino/simpleplay.png',
    mobileImg: '/img/game/live-casino/simpleplay.png',
    partner_provider: PARTNER_PROVIDER.simpleplay,
    is_require_login: true,
    // lobbyCate: '6',
    isLobby: false,
  },
];

export const casinoItems: any = [
  // {
  //   key: 'pragmatic',
  //   icon: '/img/menu/slot/pragmatic.png',
  //   img: '/img/game/slot/banner_1.png',
  //   mobileImg: '/img/game/slot/banner_mb_1.png',
  //   partner_provider: PARTNER_PROVIDER.pragmatic,
  //   lobbyCate: 2,
  // },
  // {
  //   key: 'microgaming',
  //   icon: '/img/menu/slot/microgaming.png',
  //   img: '/img/game/slot/banner_2.png',
  //   mobileImg: '/img/game/slot/banner_mb_2.png',
  //   partner_provider: PARTNER_PROVIDER.microgaming,
  //   lobbyCate: 2,
  // },
  // {
  //   key: 'fachai',
  //   icon: '/img/menu/slot/fachai.png',
  //   img: '/img/game/slot/banner_3.png',
  //   mobileImg: '/img/game/slot/banner_mb_3.png',
  //   partner_provider: PARTNER_PROVIDER.fachai,
  //   lobbyCate: 2,
  // },
  // {
  //   key: 'techplay',
  //   icon: '/img/menu/slot/techplay.png',
  //   img: '/img/game/slot/banner_12.png',
  //   mobileImg: '/img/game/slot/banner_mb_12.png',
  //   partner_provider: PARTNER_PROVIDER.vingame,
  //   lobbyCate: 2,
  // },
  // {
  //   key: 'jili',
  //   icon: '/img/menu/slot/jili.png',
  //   img: '/img/game/slot/banner_4.png',
  //   mobileImg: '/img/game/slot/banner_mb_4.png',
  //   partner_provider: PARTNER_PROVIDER.jili,
  //   lobbyCate: 2,
  // },
  {
    key: 'evo_multi',
    icon: '/img/menu/slot/evo_multi.png',
    img: '/img/game/slot/banner_5.png',
    mobileImg: '/img/game/slot/banner_mb_5.png',
    partner_provider: PARTNER_PROVIDER.evo_multi,
    // is_require_login: true,
    lobbyCate: 2,
  },
  // {
  //   key: 'jdb',
  //   icon: '/img/menu/slot/jdb.png',
  //   img: '/img/game/slot/banner_6.png',
  //   mobileImg: '/img/game/slot/banner_mb_6.png',
  //   partner_provider: PARTNER_PROVIDER.jdb,
  //   lobbyCate: 2,
  // },
  // {
  //   key: 'pgsoft',
  //   icon: '/img/menu/slot/pgsoft.png',
  //   img: '/img/game/slot/banner_7.png',
  //   mobileImg: '/img/game/slot/banner_mb_7.png',
  //   partner_provider: PARTNER_PROVIDER.pgsoft,
  //   lobbyCate: 2,
  // },
  // {
  //   key: 'askmebet',
  //   icon: '/img/menu/slot/askmebet.png',
  //   img: '/img/game/slot/banner_8.png',
  //   mobileImg: '/img/game/slot/banner_mb_8.png',
  //   partner_provider: PARTNER_PROVIDER.amb,
  //   lobbyCate: 2,
  // },
  {
    key: 'playngo',
    icon: '/img/menu/slot/playngo.png',
    img: '/img/game/slot/banner_9.png',
    mobileImg: '/img/game/slot/banner_mb_9.png',
    partner_provider: PARTNER_PROVIDER.playngo,
    lobbyCate: 2,
  },
  {
    key: 'netten',
    icon: '/img/menu/slot/netten.png',
    img: '/img/game/slot/banner_10.png',
    mobileImg: '/img/game/slot/banner_mb_10.png',
    partner_provider: PARTNER_PROVIDER.netent,
    lobbyCate: 2,
  },
  // {
  //   key: 'redtiger',
  //   icon: '/img/menu/slot/redtiger.png',
  //   img: '/img/game/slot/banner_11.png',
  //   mobileImg: '/img/game/slot/banner_mb_11.png',
  //   partner_provider: PARTNER_PROVIDER.redtiger,
  //   lobbyCate: 2,
  //   is_require_login: true,
  // },
  {
    key: PARTNER_PROVIDER.kingmaker,
    icon: '/img/menu/slot/kingmidas.png',
    img: '/img/game/slot/banner_11.png',
    mobileImg: '/img/game/slot/banner_mb_11.png',
    partner_provider: PARTNER_PROVIDER.kingmaker,
    lobbyCate: 2,
    is_require_login: true,
  },
  {
    key: 'simpleplay',
    icon: '/img/menu/slot/simpleplay.png',
    img: '/img/game/slot/simpleplay.png',
    mobileImg: '/img/game/slot/simpleplay.png',
    partner_provider: PARTNER_PROVIDER.simpleplay,
    lobbyCate: 2,
  },
];

export const fishing: any = [
  // {
  //   key: 'fachai',
  //   icon: '/img/menu/fishing/fachai.png',
  //   img: '/img/game/fishing/banner_1.png',
  //   mobileImg: '/img/game/fishing/banner_mb_1.png',
  //   partner_provider: PARTNER_PROVIDER.fachai,
  //   lobbyCate: 9,
  // },
  // {
  //   key: 'jili',
  //   icon: '/img/menu/fishing/jili.png',
  //   img: '/img/game/fishing/banner_2.png',
  //   mobileImg: '/img/game/fishing/banner_mb_2.png',
  //   partner_provider: PARTNER_PROVIDER.jili,
  //   lobbyCate: 9,
  // },
  // {
  //   key: 'askmebet',
  //   icon: '/img/menu/fishing/askmebet.png',
  //   img: '/img/game/fishing/banner_3.png',
  //   mobileImg: '/img/game/fishing/banner_mb_3.png',
  //   partner_provider: PARTNER_PROVIDER.amb,
  //   lobbyCate: 9,
  // },
  {
    key: 'techplay',
    icon: '/img/menu/fishing/techplay.png',
    img: '/img/game/fishing/banner_4.png',
    mobileImg: '/img/game/fishing/banner_mb_4.png',
    partner_provider: PARTNER_PROVIDER.vingame,
    lobbyCate: 9,
  },
  // {
  //   key: 'jdb',
  //   icon: '/img/menu/fishing/jdb.png',
  //   img: '/img/game/fishing/banner_5.png',
  //   mobileImg: '/img/game/fishing/banner_mb_5.png',
  //   partner_provider: PARTNER_PROVIDER.jdb,
  //   lobbyCate: 9,
  // },
  // {
  //   key: 'simpleplay',
  //   icon: '/img/menu/fishing/simpleplay.png',
  //   img: '/img/game/fishing/simpleplay.png',
  //   mobileImg: '/img/game/fishing/simpleplay.png',
  //   partner_provider: PARTNER_PROVIDER.simpleplay,
  //   lobbyCate: 9,
  // },
];

export const esports: any = [
  {
    key: 'sbo',
    mobileImg: '/img/game/sport/sbo_virtual_mb.png',
    icon: '/img/menu/sports/virtual_sbo.png',
    imgPC: '/img/game/sport/game_sbo.png',
    partner_provider: PARTNER_PROVIDER.sbo,
    category_name: 'VirtualSports',
    odd_style: 'MY',
    odds_mode: 'double',
    is_new_tab: false,
    is_require_login: true,
    colorBtn: '#58F5FF',
    img: '/img/game/sport/banner_esport_2.png',
    isVirtual: true,
  },
  {
    key: 'saba',
    icon: '/img/menu/sports/saba_2.png',
    imgPC: '/img/game/sport/esport_game_1.png',
    img: '/img/game/sport/banner_esport_1.png',
    partner_provider: PARTNER_PROVIDER.esport,
    is_new_tab: false,
    colorBtn: '#3A56E8',
    isVirtual: true,
    mobileImg: '/img/game/sport/saba_virtual_mb.png',
  },
  {
    key: 'im_cs',
    icon: '/img/menu/sports/virtual_saba_2.png',
    imgPC: '/img/game/sport/esport_game_2.png',
    img: '/img/game/sport/banner_esport_2.png',
    partner_provider: PARTNER_PROVIDER.sportv,
    is_new_tab: false,
    colorBtn: '#0EC1E8',
    isVirtual: false,
    mobileImg: '/img/game/sport/saba_mb.png',
  },
];
export const slotItems: any = [
  {
    key: 'pragmatic',
    icon: '/img/menu/slot/pragmatic.png',
    img: '/img/game/slot/banner_1.png',
    mobileImg: '/img/game/slot/banner_mb_1.png',
    partner_provider: PARTNER_PROVIDER.pragmatic,
    lobbyCate: 3,
  },
  {
    key: 'microgaming',
    icon: '/img/menu/slot/microgaming.png',
    img: '/img/game/slot/banner_2.png',
    mobileImg: '/img/game/slot/banner_mb_2.png',
    partner_provider: PARTNER_PROVIDER.microgaming,
    lobbyCate: 3,
  },
  {
    key: 'fachai',
    icon: '/img/menu/slot/fachai.png',
    img: '/img/game/slot/banner_3.png',
    mobileImg: '/img/game/slot/banner_mb_3.png',
    partner_provider: PARTNER_PROVIDER.fachai,
    lobbyCate: 3,
  },
  {
    key: 'techplay',
    icon: '/img/menu/slot/techplay.png',
    img: '/img/game/slot/banner_12.png',
    mobileImg: '/img/game/slot/banner_mb_12.png',
    partner_provider: PARTNER_PROVIDER.vingame,
    lobbyCate: 3,
  },
  {
    key: 'jili',
    icon: '/img/menu/slot/jili.png',
    img: '/img/game/slot/banner_4.png',
    mobileImg: '/img/game/slot/banner_mb_4.png',
    partner_provider: PARTNER_PROVIDER.jili,
    lobbyCate: 3,
  },
  {
    key: 'evoplay',
    icon: '/img/menu/slot/evoplay.png',
    img: '/img/game/slot/banner_5.png',
    mobileImg: '/img/game/slot/banner_mb_5.png',
    partner_provider: PARTNER_PROVIDER.evoplay,
    // is_require_login: true,
    lobbyCate: 3,
  },
  {
    key: 'jdb',
    icon: '/img/menu/slot/jdb.png',
    img: '/img/game/slot/banner_6.png',
    mobileImg: '/img/game/slot/banner_mb_6.png',
    partner_provider: PARTNER_PROVIDER.jdb,
    lobbyCate: 3,
  },
  {
    key: 'pgsoft',
    icon: '/img/menu/slot/pgsoft.png',
    img: '/img/game/slot/banner_7.png',
    mobileImg: '/img/game/slot/banner_mb_7.png',
    partner_provider: PARTNER_PROVIDER.pgsoft,
    lobbyCate: 3,
  },
  {
    key: 'askmebet',
    icon: '/img/menu/slot/askmebet.png',
    img: '/img/game/slot/banner_8.png',
    mobileImg: '/img/game/slot/banner_mb_8.png',
    partner_provider: PARTNER_PROVIDER.amb,
    lobbyCate: 3,
  },
  {
    key: 'playngo',
    icon: '/img/menu/slot/playngo.png',
    img: '/img/game/slot/banner_9.png',
    mobileImg: '/img/game/slot/banner_mb_9.png',
    partner_provider: PARTNER_PROVIDER.playngo,
    lobbyCate: 3,
  },
  {
    key: 'netten',
    icon: '/img/menu/slot/netten.png',
    img: '/img/game/slot/banner_10.png',
    mobileImg: '/img/game/slot/banner_mb_10.png',
    partner_provider: PARTNER_PROVIDER.netent,
    lobbyCate: 3,
  },
  {
    key: 'redtiger',
    icon: '/img/menu/slot/redtiger.png',
    img: '/img/game/slot/banner_11.png',
    mobileImg: '/img/game/slot/banner_mb_11.png',
    partner_provider: PARTNER_PROVIDER.redtiger,
    lobbyCate: 3,
    is_require_login: true,
  },
  {
    key: PARTNER_PROVIDER.hacksaw,
    icon: '/img/menu/slot/hacksaw.png',
    img: '/img/game/slot/banner_11.png',
    mobileImg: '/img/game/slot/banner_mb_11.png',
    partner_provider: PARTNER_PROVIDER.hacksaw,
    lobbyCate: 3,
    is_require_login: true,
  },
  {
    key: PARTNER_PROVIDER.nextspin,
    icon: '/img/menu/slot/nextspin.png',
    img: '/img/game/slot/banner_11.png',
    mobileImg: '/img/game/slot/banner_mb_11.png',
    partner_provider: PARTNER_PROVIDER.nextspin,
    lobbyCate: 3,
    is_require_login: true,
  },
  {
    key: PARTNER_PROVIDER.nlc,
    icon: '/img/menu/slot/nlc.png',
    img: '/img/game/slot/banner_11.png',
    mobileImg: '/img/game/slot/banner_mb_11.png',
    partner_provider: PARTNER_PROVIDER.nlc,
    lobbyCate: 3,
    is_require_login: true,
  },
  {
    key: PARTNER_PROVIDER.simpleplay,
    icon: '/img/menu/slot/simpleplay.png',
    img: '/img/game/slot/simpleplay.png',
    mobileImg: '/img/game/slot/banner_mb_11.png',
    partner_provider: PARTNER_PROVIDER.simpleplay,
    lobbyCate: 3,
    is_require_login: true,
  },
];

export const sports: any = [
  {
    key: 'sbo',
    mobileImg: '/img/game/sport/banner_mb_sbo.png',
    icon: '/img/menu/sports/sbo.png',
    partner_provider: PARTNER_PROVIDER.sbo,
    category_name: 'SportsBook',
    odd_style: 'MY',
    odds_mode: 'double',
    is_new_tab: false,
    is_require_login: true,
  },
  {
    key: 'athena',
    mobileImg: '/img/game/sport/banner_mb_saba.png',
    icon: '/img/menu/sports/athena.png',
    partner_provider: PARTNER_PROVIDER.athena,
    is_new_tab: false,
  },
  {
    key: 'ksport',
    mobileImg: '/img/game/sport/banner_mb_ksport.png',
    icon: '/img/menu/sports/ksport.png',
    partner_provider: PARTNER_PROVIDER.ksport,
    is_new_tab: false,
  },
  {
    key: 'bti',
    mobileImg: '/img/game/sport/banner_mb_bti.png',
    icon: '/img/menu/sports/bti.png',
    partner_provider: PARTNER_PROVIDER.bti,
    is_new_tab: false,
  },
];

export const lottery: any = [
  // {
  //   key: 'saba',
  //   icon: '/img/menu/lottery/saba.png',
  //   img: '/img/game/lottery/banner_1.png',
  //   mobileImg: '/img/game/lottery/banner_mb_1.png',
  //   partner_provider: PARTNER_PROVIDER.athena_keno,
  //   is_new_tab: false,
  //   is_require_login: true,
  // },
  // {
  //   key: 'askmelotto',
  //   icon: '/img/menu/lottery/askmelotto.png',
  //   img: '/img/game/lottery/banner_askmelotto.png',
  //   mobileImg: '/img/game/lottery/banner_mb_askmelotto.png',
  //   partner_provider: PARTNER_PROVIDER.askmelotto,
  //   is_new_tab: false,
  //   lobbyCate: 7,
  // },
  // {
  //   key: 'ibc',
  //   icon: '/img/menu/lottery/ibc.png',
  //   img: '/img/game/lottery/banner_ibc.png',
  //   mobileImg: '/img/game/lottery/banner_mb_ibc.png',
  //   // partner_provider: {
  //   //   [PARTNER_PROVIDER.ibc]: `${PARTNER_PROVIDER.athena_keno},${PARTNER_PROVIDER.athena_number}`,
  //   // },
  //   // partner_provider: PARTNER_PROVIDER.ibc,
  //   partner_provider: `${PARTNER_PROVIDER.athena_keno},${PARTNER_PROVIDER.athena_number}`,
  //   is_new_tab: false,
  //   lobbyCate: 7,
  // },
];

// const table: any = [
//   {
//     key: 'spribe',
//     icon: '/img/menu/table/spribe.png',
//     img: '/img/game/lottery/banner_2.png',
//     mobileImg: '/img/game/lottery/banner_mb_2.png',
//     partner_provider: PARTNER_PROVIDER.spribe_turbo,
//     // lobbyCate: 2,
//     lobbyCate: '2,3',
//   },
//   {
//     key: 'playngo',
//     icon: '/img/menu/table/playngo.png',
//     img: '/img/game/lottery/banner_playngo.png',
//     mobileImg: '/img/game/lottery/playngo.png',
//     partner_provider: PARTNER_PROVIDER.playngo,
//     // lobbyCate: 2,
//     lobbyCate: '2,3',
//   },
//   {
//     key: 'netent',
//     icon: '/img/menu/table/netent.png',
//     img: '/img/game/lottery/banner_netent.png',
//     mobileImg: '/img/game/lottery/netent.png',
//     partner_provider: PARTNER_PROVIDER.netent,
//     // lobbyCate: 2,
//     lobbyCate: '2,3',
//   },
// ];

export const cockfight: any = [
  {
    key: 'techplay',
    icon: '/img/menu/cockfight/amb.png',
    img: '/img/game/cock-fight/banner_1.png',
    mobileImg: '/img/game/cock-fight/banner_mb_1.png',
    partner_provider: PARTNER_PROVIDER.cockfight,
    is_require_login: true,
  },
];

export const ProviderNonLobby: any[] = [
  {
    label: 'Aesexy',
    slug: PARTNER_PROVIDER.aesexy,
    link: '/game/provider/' + PARTNER_PROVIDER.aesexy,
  },
  {
    label: 'All Bet',
    slug: PARTNER_PROVIDER.allbet,
    link: '/game/provider/' + PARTNER_PROVIDER.allbet,
  },
  {
    label: 'Techplay',
    slug: PARTNER_PROVIDER.vingame,
    link: '/game/provider/' + PARTNER_PROVIDER.vingame,
  },
  {
    label: 'Fa Chai',
    slug: PARTNER_PROVIDER.fachai,
    link: '/game/provider/' + PARTNER_PROVIDER.fachai,
  },
  {
    label: 'Jili',
    slug: PARTNER_PROVIDER.jili,
    link: '/game/provider/' + PARTNER_PROVIDER.jili,
  },
  {
    label: 'JDB',
    slug: PARTNER_PROVIDER.jdb,
    link: '/game/provider/' + PARTNER_PROVIDER.jdb,
  },
  {
    label: 'PG soft',
    slug: PARTNER_PROVIDER.pgsoft,
    link: '/game/provider/' + PARTNER_PROVIDER.pgsoft,
  },
  {
    label: 'Askmebet',
    slug: PARTNER_PROVIDER.amb,
    link: '/game/provider/' + PARTNER_PROVIDER.amb,
  },
  {
    label: 'Playngo',
    slug: PARTNER_PROVIDER.playngo,
    link: '/game/provider/' + PARTNER_PROVIDER.playngo,
  },
  {
    label: 'Sprite',
    slug: PARTNER_PROVIDER.spribe_turbo,
    link: '/game/provider/' + PARTNER_PROVIDER.spribe_turbo,
  },
  {
    label: 'Pragmatic',
    slug: PARTNER_PROVIDER.pragmatic,
    link: '/game/provider/' + PARTNER_PROVIDER.pragmatic,
  },
  {
    label: 'Microgaming',
    slug: PARTNER_PROVIDER.microgaming,
    link: '/game/provider/' + PARTNER_PROVIDER.microgaming,
  },
  {
    label: 'Playngo',
    slug: PARTNER_PROVIDER.playngo,
    link: '/game/provider/' + PARTNER_PROVIDER.playngo,
  },
  {
    label: 'Netent',
    slug: PARTNER_PROVIDER.netent,
    link: '/game/provider/' + PARTNER_PROVIDER.netent,
  },
  // {
  //   label: 'Dreamgaming',
  //   slug: PARTNER_PROVIDER.dreamgaming,
  //   link: '/game/provider/' + PARTNER_PROVIDER.dreamgaming,
  // },
  {
    label: 'Ibc',
    // Slug to check display path on pc
    slug: [PARTNER_PROVIDER.athena_keno, PARTNER_PROVIDER.athena_number],
    link: '/game/provider/' + PARTNER_PROVIDER.ibc,
  },
  {
    label: 'Askmelotto',
    slug: PARTNER_PROVIDER.askmelotto,
    link: '/game/provider/' + PARTNER_PROVIDER.askmelotto,
  },
  {
    label: 'Evoplay',
    slug: PARTNER_PROVIDER.evoplay,
    link: '/game/provider/' + PARTNER_PROVIDER.evoplay,
  },
  {
    label: 'Redtiger',
    slug: PARTNER_PROVIDER.redtiger,
    link: '/game/provider/' + PARTNER_PROVIDER.redtiger,
  },
];

export const LobbyCate: any = {
  // hot: { title: 'header.menuItem13', slug: 'hot', label: 'Hot' },
  '2': { title: 'header.menuItem18', slug: 'table-game', label: 'Table Games' },
  '9': { title: 'header.menuItem7', slug: 'fishing', label: 'Fishing' },
  '3': { title: 'header.menuItem3', slug: 'slots', label: 'Slots' },
  '6': { title: 'header.menuItem1', slug: 'live-casino', label: 'Live Casino' },
  '7': { title: 'header.menuItem12', slug: 'lottery', label: 'Lottery' },
  '2,3': { title: 'header.menuItem14', slug: 'tables', label: 'Tables' },
};

export const displayMapping: any = {
  hot: 'hot2',
  slots: 3,
  'live-casino': 6,
  fishing: 9,
  tables: 2,
  lottery: 7,
  // casino: '2,3',
  // casino: 2,
};

export const lobbyCateProvider: any = {
  hot: {
    partner_provider: [
      PARTNER_PROVIDER.vingame,
      PARTNER_PROVIDER.jili,
      PARTNER_PROVIDER.sagaming,
      PARTNER_PROVIDER.pragmatic,
      PARTNER_PROVIDER.vivo_multi,
    ],
    lobbyCate: 'hot',
    slug: 'hot',
    label: 'Hot',
  },
  3: {
    partner_provider: [
      PARTNER_PROVIDER.pragmatic,
      PARTNER_PROVIDER.jili,
      PARTNER_PROVIDER.vingame,
      PARTNER_PROVIDER.microgaming,
      PARTNER_PROVIDER.fachai,
      PARTNER_PROVIDER.jdb,
      PARTNER_PROVIDER.pgsoft,
      PARTNER_PROVIDER.amb,
      PARTNER_PROVIDER.playngo,
      PARTNER_PROVIDER.evoplay,
      PARTNER_PROVIDER.netent,
      PARTNER_PROVIDER.redtiger,
      PARTNER_PROVIDER.hacksaw,
      PARTNER_PROVIDER.nextspin,
      PARTNER_PROVIDER.nlc,
      PARTNER_PROVIDER.simpleplay,
    ],
    lobbyCate: 3,
    slug: 'slots',
    label: 'Slots',
    categoriesOption: ['11', '2'],
  },
  6: {
    partner_provider: [
      PARTNER_PROVIDER.viacasino,
      PARTNER_PROVIDER.sagaming,
      PARTNER_PROVIDER.pragmatic,
      PARTNER_PROVIDER.ezugi,
      PARTNER_PROVIDER.microgaming,
      PARTNER_PROVIDER.allbet,
      PARTNER_PROVIDER.aesexy,
      PARTNER_PROVIDER.evo_multi,
      PARTNER_PROVIDER.vivo_multi,
    ],
    categoriesOption: ['35', '3', '17', '15', '5', '32', '6'],
    lobbyCate: '6',
    slug: 'live-casino',
    label: 'Live Casino',
  },
  9: {
    partner_provider: [
      // PARTNER_PROVIDER.fachai,
      // PARTNER_PROVIDER.jili,
      // PARTNER_PROVIDER.amb,
      PARTNER_PROVIDER.vingame,
      // PARTNER_PROVIDER.jdb,
      // PARTNER_PROVIDER.simpleplay,
    ],
    lobbyCate: 9,
    slug: 'fishing',
    label: 'Fishing',
    categoriesOption: ['31', '30'],
  },
  // 2: {
  //   partner_provider: [
  //     PARTNER_PROVIDER.spribe_turbo,
  //     PARTNER_PROVIDER.playngo,
  //     PARTNER_PROVIDER.netent,
  //   ],
  //   lobbyCate: 2,
  //   slug: 'table',
  //   label: 'Table',
  // },
  7: {
    partner_provider: [
      // PARTNER_PROVIDER.askmelotto,
      // `${PARTNER_PROVIDER.athena_keno},${PARTNER_PROVIDER.athena_number}`,
    ],
    categoriesOption: ['13', '12'],
    lobbyCate: 7,
    slug: 'lottery',
    label: 'Lottery',
  },
  // tables & slots merge
  // '2,3': {
  //   partner_provider: [
  //     PARTNER_PROVIDER.pragmatic,
  //     PARTNER_PROVIDER.jili,
  //     PARTNER_PROVIDER.vingame,
  //     PARTNER_PROVIDER.microgaming,
  //     PARTNER_PROVIDER.fachai,
  //     PARTNER_PROVIDER.jdb,
  //     PARTNER_PROVIDER.pgsoft,
  //     PARTNER_PROVIDER.amb,
  //     PARTNER_PROVIDER.playngo,
  //     PARTNER_PROVIDER.evoplay,
  //     PARTNER_PROVIDER.netent,
  //     PARTNER_PROVIDER.redtiger,
  //     PARTNER_PROVIDER.spribe_turbo,
  //   ],
  //   categoriesOption: ['18', '11', '21'],

  //   lobbyCate: '2,3',
  //   slug: 'casino',
  //   label: 'Casino',
  // },
  // card games
  2: {
    partner_provider: [
      // PARTNER_PROVIDER.pragmatic,
      // PARTNER_PROVIDER.jili,
      // PARTNER_PROVIDER.vingame,
      // PARTNER_PROVIDER.microgaming,
      // PARTNER_PROVIDER.fachai,
      // PARTNER_PROVIDER.jdb,
      // PARTNER_PROVIDER.pgsoft,
      // PARTNER_PROVIDER.amb,
      PARTNER_PROVIDER.playngo,
      PARTNER_PROVIDER.evo_multi,
      PARTNER_PROVIDER.netent,
      // PARTNER_PROVIDER.redtiger,
      PARTNER_PROVIDER.spribe_turbo,
      // PARTNER_PROVIDER.kingmaker,
      PARTNER_PROVIDER.simpleplay,
    ],
    categoriesOption: ['34', '5', '15', '21'],
    lobbyCate: 2,
    slug: 'tables',
    label: 'Tables',
  },
};
