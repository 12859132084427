/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { getCookie } from 'cookies-next';
import qs from 'qs';

import { isDebug } from '@/lib/utils';

import { getTranslation } from '@/app/i18n/i18nUtils';
import { languages } from '@/app/i18n/settings';
import { isLocal } from '@/constant/env';
interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  metadata?: {
    startTime: Date;
  };
}
// const defaultCacheTime = 1200; // 20m
const isServer = typeof window === 'undefined';
const prefixVersion = '/api/v2';
const baseURL =
  (isServer
    ? process.env.NEXT_PUBLIC_SSR_BASE_URL
    : process.env.NEXT_PUBLIC_API_BASE_URL) + prefixVersion;
const requestCache = 'no-store';

export const http = axios.create({
  baseURL: baseURL,
  timeout: 40000,
  timeoutErrorMessage: 'Server đang bận, Vui lòng thử lại sau',
  headers: {
    'Content-Type': 'application/json',
  },
});
http.interceptors.request.use(
  async (config: CustomAxiosRequestConfig) => {
    config.metadata = { startTime: new Date() };
    if (!isServer) {
      const token = getCookie('token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }
    return config;
  },
  (err) => {
    console.log('err:🔥', err);
    throw new Error(err);
    // some action
  }
);

http.interceptors.response.use(
  (response: any) => {
    const endTime: any = new Date();
    const elapsedTime = endTime - response.config.metadata.startTime;
    if (isDebug()) {
      console.log(
        `Request to ${response.config.url} took ${elapsedTime} milliseconds`
      );
    }
    return response;
  },
  async (error) => {
    // Handle specific error cases here
    if (error.response) {
      const { status, data } = error.response;
      if (status === 422) {
        const errors = Object.entries(data.data).map(
          ([fieldName, errorMessage]) => {
            return {
              name: fieldName,
              errors: [errorMessage],
            };
          }
        );
        console.log('errors:🔥', errors);
        // Perform specific actions for validation errors here
        return Promise.reject({ ...data, errors: errors }); // Reject with validation error data
      }
      // For other errors, return the error response or reject with the error
      return Promise.reject(error.response?.data || error);
    }
  }
);
// Hàm dùng chung để thực hiện yêu cầu HTTP
export async function apiRequest<T>(
  config: AxiosRequestConfig & { fetchOptions?: RequestInit } & {
    lang?: string;
    subURL?: string;
  }
): Promise<T | any> {
  try {
    config = {
      ...config,
      headers: {
        'Accept-Language': config?.lang || getCookie('i18next') || languages[0],
        ...config?.headers,
      },
    };

    if (isServer) {
      const params = config.params;
      const url = new URL(`${baseURL}${config.url}`);

      if (params && Object.keys(params).length > 0) {
        Object.keys(params).forEach((key) => {
          if (!url.searchParams.has(key)) {
            url.searchParams.append(key, params[key]);
          }
        });
      }
      config.fetchOptions = {
        ...(config?.fetchOptions || {}),
        cache: requestCache,
        headers: {
          'Accept-Language': config?.lang || languages[0],
        },
      };
      const response = await fetch(url.toString(), {
        ...config.fetchOptions,
      });

      const responseJson = await response.json();

      if (isLocal) {
        console.log(
          `[${config.method?.toLocaleUpperCase()}] Request -`,
          url.toString()
        );

        if (config.data) console.log('body: ', config.data);
        if (params) console.log('params: ', params);
        console.log('Response -> ', responseJson, '\n');
      }

      return responseJson;
    }

    const response: AxiosResponse<T> = await http(config);
    return response?.data;
  } catch (error: any) {
    const url = new URL(`${baseURL}${config.url}`);
    // const lng = config?.lang || getCookie('i18next') || languages[0];
    const statusMsg = await getTranslation([
      `${config.subURL || config.url}.${error?.code}` || '',
      `${error?.status || ''}` || '',
      `${error?.code || ''}` || '',
      'UNKNOWN_ERROR',
    ]);
    // console.log('statusMsg:', { statusMsg });
    const errorMsg = statusMsg;
    console.log('URL: ', url);
    console.log('apiRequest failure by:🔥', error, config);
    return {
      ...error,
      msg: errorMsg,
      message: errorMsg,
    };
  }
}

// Phương thức GET
export async function getRequest<T>(
  url: string,
  params?: any,
  axiosConfig?: AxiosRequestConfig
): Promise<T> {
  // Thêm params và paramsSerializer nếu được cung cấp
  let config: AxiosRequestConfig = {};
  if (params) {
    config = {
      ...config,
      params, // Đối số tham số truy vấn
      paramsSerializer: (pr: any) => {
        return qs.stringify(pr, { arrayFormat: 'brackets' }); // Sử dụng thư viện querystringify (qs) để tuỳ chỉnh paramsSerializer
      },
    };
  }
  return apiRequest<T>({
    ...config,
    ...(axiosConfig || {}),
    method: 'get',
    url,
  });
}

// Phương thức POST
export async function postRequest<T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig & { subURL?: string }
): Promise<T> {
  return apiRequest<T>({
    ...config,
    method: 'post',
    url,
    data,
  });
}

// Phương thức PUT
export async function putRequest<T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig & { subURL?: string }
): Promise<T> {
  return apiRequest<T>({
    ...config,
    method: 'put',
    url,
    data,
  });
}

export async function deleteRequest<T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig & { subURL?: string }
): Promise<T> {
  return apiRequest<T>({
    ...config,
    method: 'delete',
    url,
    data,
  });
}
