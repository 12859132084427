import { deleteCookie, getCookie } from 'cookies-next';
import fuzzy from 'fuzzy';
import i18next from 'i18next';

import { ICasino, TUser } from '@/lib/dto';
import { removeAccent } from '@/lib/helper';
import { deleteRequest, getRequest, postRequest, putRequest } from '@/lib/http';
import { isMobile } from '@/lib/utils';
export interface IResponseCommon {
  data: any;
  code: number;
  msg?: string;
  errors?: any;
  message?: any;
  status?: string;
}
//TODO: Need splice file [auth,user,game]
/*** ========Auth====== */

export const login = async (data: any): Promise<IResponseCommon> => {
  return await postRequest('/auth/login', data);
};

export const register = async (data: any): Promise<IResponseCommon> => {
  return await postRequest('/auth/register', data);
};

export const logout = async (): Promise<IResponseCommon> => {
  return await postRequest('/user/logout');
};

export const registerAgent = async (data: any): Promise<IResponseCommon> => {
  return await postRequest('/auth/register', {
    ...data,
    is_agent: true,
  });
};

/*** ========User====== */

export const profile = async (): Promise<TUser> => {
  const token = getCookie('token');
  if (!token) {
    return;
  }
  const resUser: IResponseCommon = await getRequest('/user/profile');
  if (resUser.code !== 200) {
    deleteCookie('token');
    deleteCookie('refresh_token');
    if (typeof window !== 'undefined') {
      window?.location?.reload();
    }
    return resUser?.data;
  }
  return resUser.data;
};

export const historyBet = async (data: any): Promise<IResponseCommon> => {
  return await getRequest('/user/bet-history', data);
};

export const historyTransaction = async (
  data: any
): Promise<IResponseCommon> => {
  return await getRequest('/user/transaction-history', data);
};

export const becomeToAgent = async (data: any): Promise<IResponseCommon> => {
  return await postRequest('/user/become-to-agent', data);
};

export const getMyBanks = async (data: any): Promise<IResponseCommon> => {
  return await getRequest('/user/bank', data);
};

export const getMyBankName = async (data: any): Promise<IResponseCommon> => {
  return await getRequest('/user/bank/account-name', data);
};

export const addMyBank = async (data: any): Promise<IResponseCommon> => {
  return await postRequest('/user/bank', data);
};

export const postUpdatePassword = async (
  data: any
): Promise<IResponseCommon> => {
  return await postRequest('/user/update-password', data);
};

export const postCheckTele = async (): Promise<IResponseCommon> => {
  return await postRequest('/user/check-tele-active');
};

export const postActiveTelegram = async (
  data: any
): Promise<IResponseCommon> => {
  return await postRequest('/user/active-tele', data);
};

export const updateUserInfo = async (data: any): Promise<IResponseCommon> => {
  return await postRequest('/user/update-profile', data);
};

export const getOptTelegram = async (data: any): Promise<IResponseCommon> => {
  return await getRequest('/user/get-otp-tele', data);
};

export const getOptPhone = async (data: any): Promise<IResponseCommon> => {
  return await getRequest('/user/get-sms-reset-pass', data);
};

export const sendNewPassBySMS = async (data: any): Promise<IResponseCommon> => {
  return await postRequest('/user/reset-password-sms', data);
};

export const getOptSms = async (data: any): Promise<IResponseCommon> => {
  return await getRequest('/user/get-otp-sms', data);
};

export const getOptEmail = async (data: any): Promise<IResponseCommon> => {
  return await getRequest('/user/get-verify-otp-email', data);
};

export const verifyOptEmail = async (data: any): Promise<IResponseCommon> => {
  return await postRequest('/user/verify-otp-email', data);
};

export const sendOptTelegram = async (data: any): Promise<IResponseCommon> => {
  return await postRequest('/user/verify-otp-tele', data);
};

export const sendOptSms = async (data: any): Promise<IResponseCommon> => {
  return await postRequest('/user/verify-otp-sms', data);
};

export const resetPassword = async (data: any): Promise<IResponseCommon> => {
  return await postRequest('/user/reset-password', data);
};

export const getMyPromotion = async (args?: any): Promise<IResponseCommon> => {
  return await getRequest('/user/promotion', args);
};

export const getMyPackage = async (args?: any): Promise<IResponseCommon> => {
  return await getRequest('/user/get-packages', args);
};

export const getMyPackageMaxAmount = async (
  args?: any
): Promise<IResponseCommon> => {
  return await getRequest('/user/calculate-welcome-rolling', args);
};

export const postUpdatePackage = async (
  args?: any
): Promise<IResponseCommon> => {
  return await postRequest('/user/update-promotion', args);
};

export const postApplyPromo = async (
  code?: string
): Promise<IResponseCommon> => {
  return await postRequest(
    '/user/receive-gift-code/' + code,
    {},
    {
      subURL: '/user/receive-gift-code',
    }
  );
};

export const postCancelPromo = async (args?: any): Promise<IResponseCommon> => {
  return await postRequest('/user/cancel-promotion', args);
};

export const getPromotionNewUser = async (): Promise<IResponseCommon> => {
  return await getRequest('/user/check-new-user-promotion');
};

export const postPromotionNewUser = async (): Promise<IResponseCommon> => {
  return await postRequest('/user/receive-new-user-promotion');
};

export const getPromotionRefund = async (
  args: any
): Promise<IResponseCommon> => {
  return await getRequest('/user/bet-statistic', args);
};

export const getNotification = async (): Promise<IResponseCommon> => {
  return await getRequest('/user/notifications');
};

export const putNotification = async (id: string): Promise<IResponseCommon> => {
  return await putRequest('/user/notifications/' + id);
};

/*** ========Game====== */
export const getHotMatch = async (args?: any): Promise<IResponseCommon> => {
  return await getRequest('/game/hot-match', args);
};

export const getGameCasino = async (params?: any): Promise<IResponseCommon> => {
  return await getRequest('/game', {
    limit: 6,
    display: 58,
    device: 'mobile',
    ...params,
  });
};

export const getGames = async (data?: any): Promise<IResponseCommon> => {
  return await getRequest('/game', {
    limit: 10,
    // device: 'mobile',
    device: isMobile() ? 'mobile' : 'pc',
    ...data,
  });
};
// Function to compare games based on jackpot
export const compareByJackpot = (a: ICasino, b: ICasino): number => {
  const jackpotA =
    typeof a.jackpot === 'string' ? parseFloat(a.jackpot) : a?.jackpot || 0;
  const jackpotB =
    typeof b.jackpot === 'string' ? parseFloat(b.jackpot) : b?.jackpot || 0;

  return jackpotB - jackpotA; // Descending order, change to jackpotA - jackpotB for ascending order
};

// Sort the array of games by jackpot
export const getGamesAndJackpot = async (data?: any): Promise<any> => {
  const isJackpot = ['3', '9', '17', '101'].includes(data.display);
  //Case if not Jackpot Call Api Normalize
  if (!isJackpot) {
    const resData: any = await getGamesV2(data);
    return {
      code: 200,
      items: resData,
      meta: resData?.meta,
    };
  }
  // Case Is Jackpot Call API fnGame and fnJackpot
  const fnJackpot = getJackpot();
  const resAll: any = await Promise.all([getGamesV2(data), fnJackpot]);
  const listGame = resAll[0] || [];
  const listJackpot = resAll[1]?.data || [];
  const dataPure = listGame.map((_: ICasino) => ({
    ..._,
    jackpot: listJackpot[_.partner_game_id] || 0,
  }));
  let jackpot = 0;
  if (data.display === '3') {
    jackpot = dataPure.reduce(
      (current: number, item: ICasino) => {
        current = Number(item?.jackpot) + Number(current);
        return current;
      },
      [0]
    );
  }
  return {
    code: resAll[1]?.code,
    items: dataPure.sort(compareByJackpot) || [],
    meta: { ...listGame?.meta, jackpot },
  };
};

export const getGamesAndJackpotV2 = async (arg?: any): Promise<any> => {
  const fnJackpot = getJackpot();
  return await Promise.all([getGamesAll(arg), fnJackpot]);
};

export const getTopJackpot = async (): Promise<IResponseCommon> => {
  return await getRequest('/game', {
    limit: 6,
    display: 3,
    device: 'mobile',
  });
};

export const getGameLiveCasino = async (): Promise<IResponseCommon> => {
  return await getRequest('/game', {
    limit: 4,
    display: 6,
    device: 'mobile',
  });
};

export const getWinner = async (): Promise<IResponseCommon> => {
  return await getRequest('/game/user-win-jackpot');
};

export const getLinkGame = async (data: any): Promise<IResponseCommon> => {
  return await getRequest('/game/url', {
    ...data,
    language: i18next.language,
  });
};

export const getJackpot = async (data?: any): Promise<IResponseCommon> => {
  return await getRequest('/game/jackpot', data);
};

export const getJackpotAll = async (args?: any): Promise<number> => {
  const res: any = await getRequest('/game/jackpot', args);
  try {
    if (res && res?.data) {
      let sum = 0;
      for (const key in res?.data) {
        if (!isNaN(parseFloat(res?.data[key]))) {
          sum += parseFloat(res?.data[key]);
        }
      }
      return sum;
    }
    return 138 * 1200 * 1200;
  } catch (error) {
    return 138 * 1200 * 1200;
  }
};

/*** ========News====== */

export const getNews = async (args?: any): Promise<IResponseCommon> => {
  return await getRequest('/news', args);
};

export const getNewsDetail = async (id?: any): Promise<IResponseCommon> => {
  return await getRequest('/news/' + id);
};

/*** ========Payment====== */

export const getBankList = async (args?: any): Promise<IResponseCommon> => {
  return await getRequest('/payment/deposit-bank-list', args);
};

export const getBanksThai = async (args?: any): Promise<IResponseCommon> => {
  return await getRequest('/payment/get-bank-thai', args);
};

export const getBanksAvailable = async (
  args?: any
): Promise<IResponseCommon> => {
  return await getRequest('/payment/bank-schedule', args);
};

export const getBankDetailWithdraw = async (
  args?: any
): Promise<IResponseCommon> => {
  return await getRequest('/payment/get-info-withdraw', args);
};

export const getBankThai = async (args?: any): Promise<IResponseCommon> => {
  return await getRequest('/payment/get-bank-thai', args);
};

export const getListCrypto = async (args?: any): Promise<IResponseCommon> => {
  return await getRequest('/payment/crypto/deposit-currency', args);
};
export const getCurrencyPriceCrypto = async (): Promise<IResponseCommon> => {
  return await getRequest('/payment/crypto/currency-price');
};

export const getListCryptoWithdraw = async (
  args?: any
): Promise<IResponseCommon> => {
  return await getRequest('/payment/crypto/withdraw-currency', args);
};
export const getLimitWidth = async (args?: any): Promise<IResponseCommon> => {
  return await getRequest('/payment/crypto/withdraw-limit', args);
};
export const getCryptoNetwork = async (
  network?: string
): Promise<IResponseCommon> => {
  return await getRequest('/payment/crypto/address/' + network);
};

export const widthDrawCrypto = async (
  payload?: any
): Promise<IResponseCommon> => {
  return await postRequest('/payment/crypto/withdraw', payload);
};

export const depositPhoneCard = async (
  args?: any
): Promise<IResponseCommon> => {
  return await postRequest('/payment/deposit-phone-card', args);
};

export const withdrawPhoneCard = async (
  args?: any
): Promise<IResponseCommon> => {
  return await postRequest('/payment/withdraw-phone-card', args);
};

export const getBankDetail = async (args?: any): Promise<IResponseCommon> => {
  return await getRequest('/payment/deposit-bank-detail', args);
};

export const postDeposit = async (args?: any): Promise<IResponseCommon> => {
  return await postRequest('/payment/deposit', args);
};

export const postDepositThai = async (args?: any): Promise<IResponseCommon> => {
  return await postRequest('/payment/deposit-thai', args);
};

export const postWithdraw = async (args?: any): Promise<IResponseCommon> => {
  return await postRequest('/payment/withdraw', args);
};

export const postDepositPayWin = async (
  args?: any
): Promise<IResponseCommon> => {
  return await postRequest('/payment/deposit-smartpay', args);
};

//TODO:Storage Game
export const getGamesAll = async (arg?: any): Promise<any> => {
  const resAll: IResponseCommon = await getRequest('/game/list-init-games', {
    device: arg?.mobile ? 'mobile' : 'pc',
  });
  return resAll?.data || {};
};

export const getGameLiveCasinoV2 = async () => {
  const allGame = await getGamesAll();
  const storeGame = [...(allGame['6'] || [])];
  return storeGame?.slice(0, 4);
};

export const getGameLiveCasinoV2MB = async ({
  mobile,
}: {
  mobile?: boolean;
}) => {
  const allGame = await getGamesAll({ mobile });
  const storeGame = [...(allGame['6'] || [])];
  return storeGame?.slice(0, 10);
};

export const getGamesV2 = async (data?: any) => {
  const allGame = await getGamesAll();
  let storeGame = [...(allGame[String(data?.display)] || [])];
  if (data?.limit) {
    storeGame = storeGame.slice(0, data?.limit);
  }
  if (data?.name) {
    storeGame = fuzzy
      .filter(removeAccent(data.name), storeGame, {
        pre: '<',
        post: '>',
        extract: function (el: any) {
          return removeAccent(el.name);
        },
      })
      .map((_) => _.original);
  }
  if (data?.sort === 'lasted') {
    storeGame = storeGame.sort((a: ICasino, b: ICasino) => b.id - a.id);
  }
  if (data?.sort === 'hot') {
    storeGame = storeGame.sort((a: ICasino, b: ICasino) => a.id - b.id);
  }
  return storeGame;
};

export const getTopJackpotV2 = async () => {
  const allGame = await getGamesAll();
  const storeGame = [...(allGame['3'] || [])];
  return storeGame?.slice(0, 6);
};

export const getGameCasinoV2 = async (args: any) => {
  const allGame = await getGamesAll();
  let storeGame = [...(allGame['58'] || [])];
  if (args.limit) {
    storeGame = storeGame.slice(0, args.limit);
  }
  return storeGame;
};

//Agent
export const getStaticAgentNewActives = async (
  args?: any
): Promise<IResponseCommon> => {
  return await getRequest('/agent/statistic/user/', args);
};

export const getStaticAgentCommission = async (
  args?: any
): Promise<IResponseCommon> => {
  return await getRequest('/agent/statistic/total-of-agent-by-day', args);
};

export const getCampaigns = async (args?: any): Promise<IResponseCommon> => {
  return await getRequest('/campaign', args);
};

export const createCampaign = async (args?: any): Promise<IResponseCommon> => {
  return await postRequest('/campaign', args);
};

export const getCampaign = async (id?: any): Promise<IResponseCommon> => {
  return await postRequest('/campaign/' + id);
};

export const updateCampaign = async (args?: any): Promise<IResponseCommon> => {
  return await putRequest('/campaign/' + args?.id, args);
};

export const deleteCampaign = async (id?: any): Promise<IResponseCommon> => {
  return await deleteRequest('/campaign/' + id);
};

export const trackDataCampaign = async (
  data: any
): Promise<IResponseCommon> => {
  return await postRequest('/campaign/tracking', data);
};

export const postUserKyc = async (args?: any): Promise<IResponseCommon> => {
  return await postRequest('/user/kyc', args);
};

export const getUserRebate = async (args?: any): Promise<IResponseCommon> => {
  return await getRequest('/user/get-user-rebate', args);
};
export const getUserRebateHistory = async (
  args?: any
): Promise<IResponseCommon> => {
  return await getRequest('/user/get-user-rebate-history', args);
};
export const getUserReferral = async (args?: any): Promise<IResponseCommon> => {
  return await getRequest('/user/referred', args);
};
export const claimReferral = async (args?: any): Promise<IResponseCommon> => {
  return await postRequest('/user/claim-referral', args);
};
