import { create } from 'zustand';

type GameModalStore = {
  setGameLoading: (id?: number) => void;
  setGameInfo: (gameInfo: any) => void;
  setLargeSize: (isLargeSize: any) => void;
  isLoadingGame: number;
  gameInfo: any;
  isLargeSize: boolean;
};

export const useGameModalStore = create<GameModalStore>((set) => ({
  isLoadingGame: 0,
  gameInfo: {
    src: '',
  },
  isLargeSize: true,
  setLargeSize: (isLargeSize) => set(() => ({ isLargeSize })),
  setGameInfo: (gameInfo) => set(() => ({ gameInfo })),
  setGameLoading: (id) => set(() => ({ isLoadingGame: id })),
}));

// type MobileHomeStore = {
//   gamePage?: string;
//   cateDetail?: string;
//   partnerSelected?: string;
//   setGamePage: (gamePage: string) => void;
//   setCateDetail: (cateDetail?: string) => void;
//   setPartnerSelected: (partnerSelected: string) => void;
// };

// export const useMobileHomeStore = create<MobileHomeStore>((set) => ({
//   gamePage: '',
//   cateDetail: '',
//   partnerSelected: '',
//   setGamePage: (gamePage: string) => set(() => ({ gamePage })),
//   setCateDetail: (cateDetail?: string) => set(() => ({ cateDetail })),
//   setPartnerSelected: (partnerSelected: string) =>
//     set(() => ({ partnerSelected })),
// }));

// export const MobileHomeContext = createContext<MobileHomeStore | null>(null)
