'use client';

import clsx from 'clsx';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import Image from 'next/image';
import React, { useState } from 'react';

import { openGameProvider } from '@/components/core/OpenGame';
import { useAppRouter } from '@/components/hooks/useAppRouter';
import useIsMobile from '@/components/hooks/useScreenSize';

import { PARTNER_PROVIDER } from '@/constant/data';
dayjs.extend(utc);
dayjs.extend(customParseFormat);

const MatchCard: React.FC<any> = ({ cardItem = {}, className }) => {
  const isMobile = useIsMobile();
  const { router } = useAppRouter();
  const [loaded, setLoaded] = useState(false);
  if (isMobile)
    return (
      <div
        key={cardItem?.id}
        className={clsx('vw:!w-full vw:min-h-[150px] relative mr-auto')}
      >
        <Image
          priority
          width={335}
          height={150}
          src='/img/game/sport/frame-match.png'
          className='absolute inset-0 bottom-0 left-0 right-0 top-0 z-0 h-full w-full'
          alt=''
          onLoad={() => {
            setLoaded(true);
          }}
        />
        <div
          className={clsx(
            'vw:min-h-[150px] absolute inset-0 z-10 h-full',
            !loaded && 'rounded-[24px] bg-[#181413]'
          )}
        >
          <div className='vw:text-12 vw:mt-[5px] w-full text-center text-[#424242]'>
            {
              // dayjs(cardItem?.text_time, 'YYYY-MM-DDTHH:mm:ss').isValid()  ?
              dayjs
                .utc(cardItem?.text_time, 'HH:mm DD-MM-YYYY')
                .local()
                .format('HH:mm - DD/MM')
            }
          </div>
          <div className='vw:mb-2 -mt-2 flex items-center justify-center gap-4'>
            <div className='grow basis-0 text-center'>
              <img
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = '/img/menu/active/sport.png';
                }}
                className='vw:w-[40px] vw:h-[40px] m-auto'
                src={cardItem?.teams?.[0]?.image}
                alt=''
              />

              <span className='vw:text-10 vw:mt-1 vw:mb-0.5 line-clamp-1 flex items-center justify-center text-ellipsis font-bold text-white'>
                <span
                  className='vw:w-3 vw:h-3 vw:mr-[3px] vw:text-[6px]  inline-flex items-center justify-center rounded-full'
                  style={{
                    background: '#00A958',
                  }}
                >
                  H
                </span>
                {cardItem?.teams?.[0]?.name}
              </span>
            </div>
            <div className='vw:mt-4 vw:mx-[-40px] text-center '>
              <div className='text-main-bg vw:text-10 line-clamp-1 text-ellipsis font-bold'>
                {cardItem?.league_name}
              </div>
              <div className='text-main-bg vw:text-14 font-bold'>VS</div>
            </div>
            <div className='grow basis-0 text-center'>
              <img
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = '/img/menu/active/sport.png';
                }}
                className='vw:w-[40px] vw:h-[40px] m-auto'
                src={cardItem?.teams?.[1]?.image}
                alt=''
              />
              <span className='vw:text-10 vw:mt-1 vw:mb-0.5 line-clamp-1 flex items-center justify-center text-ellipsis font-bold text-white'>
                {cardItem?.teams?.[1]?.name}

                <span
                  className='vw:w-3 vw:h-3 vw:ml-[3px] vw:text-[6px]  inline-flex items-center justify-center rounded-full'
                  style={{
                    background: '#A92900',
                  }}
                >
                  A
                </span>
              </span>
            </div>
          </div>
          <div className='vw:w-[243px] vw:mb-2 vw:-mt-1 mx-auto'>
            <svg
              className='mx-auto w-full'
              xmlns='http://www.w3.org/2000/svg'
              height={4}
              viewBox='0 0 259 2'
              fill='none'
            >
              <path d='M0 1H259' stroke='url(#paint0_linear_776_24814)' />
              <defs>
                <linearGradient
                  id='paint0_linear_776_24814'
                  x1={0}
                  y1='1.5'
                  x2={259}
                  y2='1.5'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stopColor='#936321' stopOpacity={0} />
                  <stop offset='0.505' stopColor='#EBCA76' />
                  <stop offset={1} stopColor='#936321' stopOpacity={0} />
                </linearGradient>
              </defs>
            </svg>

            <div className='vw:text-10 vw:py-1 -my-0.5 mx-auto flex w-full items-center justify-center text-white'>
              <span className='vw:mr-1 vw:text-9 grow basis-0 text-left'>
                HDP
              </span>
              <div className='vw:gap-1.5 flex grow basis-0 justify-end text-center'>
                <span
                  className='grow basis-0 text-center'
                  style={{
                    color: '#00A958',
                  }}
                >
                  {cardItem?.hdp?.hTeam?.odds}
                </span>
                <span className='grow basis-0 text-center'>
                  {cardItem?.hdp?.hTeam?.rate}
                </span>
                <span
                  className='grow basis-0 text-center '
                  style={{
                    color: '#A92900',
                  }}
                >
                  {cardItem?.hdp?.aTeam?.odds}
                </span>
              </div>
              <div className='vw:mx-5'></div>
              <span className='vw:mr-1.5 vw:text-9 grow basis-0 text-left'>
                O/U
              </span>
              <div className='vw:gap-1.5 flex grow basis-0 justify-end text-left'>
                <span
                  className='grow basis-0 text-center'
                  style={{
                    color: '#00A958',
                  }}
                >
                  {cardItem?.ou?.aTeam?.odds}
                </span>
                <span className='flex-1 text-center'>
                  {cardItem?.ou?.hTeam?.rate}
                </span>
                <span
                  style={{
                    color: '#A92900',
                  }}
                  className='grow basis-0 text-center'
                >
                  {cardItem?.ou?.aTeam?.odds}
                </span>
              </div>
            </div>

            <svg
              className='mx-auto w-full'
              xmlns='http://www.w3.org/2000/svg'
              height={4}
              viewBox='0 0 259 2'
              fill='none'
            >
              <path d='M0 1H259' stroke='url(#paint0_linear_776_24814)' />
              <defs>
                <linearGradient
                  id='paint0_linear_776_24814'
                  x1={0}
                  y1='1.5'
                  x2={259}
                  y2='1.5'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stopColor='#936321' stopOpacity={0} />
                  <stop offset='0.505' stopColor='#EBCA76' />
                  <stop offset={1} stopColor='#936321' stopOpacity={0} />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className='flex w-full items-center justify-center'>
            <div
              onClick={() =>
                openGameProvider(
                  {
                    ...cardItem,
                    isHotMatch: true,
                    partner_provider: PARTNER_PROVIDER.ksport,
                  },
                  router
                )
              }
              className='vw:rounded-[3px] vw:w-[61px] box cursor-hand hover-shine anim-bg-linear-1 bg-linear-1 vw:text-12 text-main-secondary mx-2 py-1 text-center font-semibold'
            >
              K-sport
            </div>

            <div
              onClick={() => {
                openGameProvider(
                  {
                    ...cardItem,
                    isHotMatch: true,
                    partner_provider: PARTNER_PROVIDER.athena,
                    is_new_tab: false,
                  },
                  router
                );
              }}
              className='vw:rounded-[3px] vw:w-[61px] box cursor-hand hover-shine anim-bg-linear-1 bg-linear-1 vw:text-12 text-main-secondary mx-2 py-1 text-center font-semibold'
            >
              SABA
            </div>

            <div
              onClick={() =>
                openGameProvider(
                  {
                    ...cardItem,
                    isHotMatch: true,
                    partner_provider: PARTNER_PROVIDER.bti,
                    is_new_tab: false,
                  },
                  router
                )
              }
              className='vw:rounded-[3px] vw:w-[61px] box cursor-hand hover-shine anim-bg-linear-1 bg-linear-1 vw:text-12 text-main-secondary mx-2 py-1 text-center font-semibold'
            >
              BTI
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div
      key={cardItem?.id}
      className={clsx(
        'relative mb-[12px] mr-auto min-h-[189px] !w-[317px]',
        className
      )}
    >
      <div className='bg-linear-1 mx-auto mt-3 h-[23px] w-1/2'></div>

      <div className='absolute inset-0 bottom-0 left-0 right-0 top-0 z-0 h-[189px] h-full w-full'>
        <svg
          className='absolute inset-0 bottom-0 left-0 right-0 top-0 z-0 !h-[189px] h-full w-full'
          viewBox='0 0 394 171'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M1 150.117V20.8827C1 15.0558 3.55605 9.52215 7.99252 5.7445C11.5892 2.68193 16.1588 1 20.8827 1H103.903C111.13 1 118.003 4.1272 122.751 9.57547L129.066 16.8222C133.814 22.2705 140.687 25.3977 147.914 25.3977H238.778C246.005 25.3977 252.878 22.2705 257.626 16.8222L263.941 9.57548C268.688 4.12721 275.562 1 282.788 1H373.117C377.841 1 382.411 2.68193 386.007 5.7445C390.444 9.52215 393 15.0558 393 20.8827V150.117C393 155.944 390.444 161.478 386.007 165.256C382.411 168.318 377.841 170 373.117 170H20.8827C16.1588 170 11.5892 168.318 7.99252 165.256C3.55606 161.478 1 155.944 1 150.117Z'
            fill='#1D1918'
          />
          <path
            d='M1 150.117V20.8827C1 15.0558 3.55605 9.52215 7.99252 5.7445C11.5892 2.68193 16.1588 1 20.8827 1H103.903C111.13 1 118.003 4.1272 122.751 9.57547L129.066 16.8222C133.814 22.2705 140.687 25.3977 147.914 25.3977H238.778C246.005 25.3977 252.878 22.2705 257.626 16.8222L263.941 9.57548C268.688 4.12721 275.562 1 282.788 1H373.117C377.841 1 382.411 2.68193 386.007 5.7445C390.444 9.52215 393 15.0558 393 20.8827V150.117C393 155.944 390.444 161.478 386.007 165.256C382.411 168.318 377.841 170 373.117 170H20.8827C16.1588 170 11.5892 168.318 7.99252 165.256C3.55606 161.478 1 155.944 1 150.117Z'
            fill='black'
            fill-opacity='0.2'
          />
          <path
            className='path-animation'
            d='M1 150.117V20.8827C1 15.0558 3.55605 9.52215 7.99252 5.7445C11.5892 2.68193 16.1588 1 20.8827 1H103.903C111.13 1 118.003 4.1272 122.751 9.57547L129.066 16.8222C133.814 22.2705 140.687 25.3977 147.914 25.3977H238.778C246.005 25.3977 252.878 22.2705 257.626 16.8222L263.941 9.57548C268.688 4.12721 275.562 1 282.788 1H373.117C377.841 1 382.411 2.68193 386.007 5.7445C390.444 9.52215 393 15.0558 393 20.8827V150.117C393 155.944 390.444 161.478 386.007 165.256C382.411 168.318 377.841 170 373.117 170H20.8827C16.1588 170 11.5892 168.318 7.99252 165.256C3.55606 161.478 1 155.944 1 150.117Z'
            stroke='url(#paint0_linear_225_3566)'
          />
          <defs className='path-animation'>
            <linearGradient
              className='path-animation'
              id='paint0_linear_225_3566'
              x1='0.981496'
              y1='85.5'
              x2='564.009'
              y2='85.5'
              gradientUnits='userSpaceOnUse'
            >
              <stop stop-color='#AA7300' />
              <stop offset='0.24' stop-color='#D3A300' />
              <stop offset='0.5' stop-color='#B67000' />
              <stop offset='0.77' stop-color='#E5B900' />
              <stop offset='1' stop-color='#BB7000' />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className='absolute inset-0 z-10 h-full min-h-[195px]'>
        <div className='text-13 mt-3.5 w-full text-center text-[#424242]'>
          {dayjs
            .utc(cardItem?.text_time, 'HH:mm DD-MM-YYYY')
            .local()
            .format('HH:mm - DD/MM')}
        </div>
        <div className='mb-2 flex items-center justify-center gap-4'>
          <div className='grow basis-0 text-center'>
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = '/img/menu/active/sport.png';
              }}
              className='m-auto h-[40px] w-[40px]'
              src={cardItem?.teams?.[0]?.image}
              alt=''
            />

            <span className='text-10 mb-0.5 mt-1 line-clamp-1 flex items-center justify-center text-ellipsis font-bold text-white'>
              <span
                className='mr-[3px] inline-flex h-3 w-3  items-center justify-center rounded-full text-[6px]'
                style={{
                  background: '#00A958',
                }}
              >
                H
              </span>
              {cardItem?.teams?.[0]?.name}
            </span>
          </div>
          <div className='mx-[-40px] mt-4 text-center '>
            <div className='text-main-bg text-10 line-clamp-1 text-ellipsis font-bold'>
              {cardItem?.league_name}
            </div>
            <div className='text-main-bg text-14 font-bold'>VS</div>
          </div>
          <div className='grow basis-0 text-center'>
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = '/img/menu/active/sport.png';
              }}
              className='m-auto h-[40px] w-[40px]'
              src={cardItem?.teams?.[1]?.image}
              alt=''
            />
            <span className='text-10 mb-0.5 mt-1 line-clamp-1 flex items-center justify-center text-ellipsis font-bold text-white'>
              {cardItem?.teams?.[1]?.name}

              <span
                className='ml-[3px] inline-flex h-3 w-3  items-center justify-center rounded-full text-[6px]'
                style={{
                  background: '#A92900',
                }}
              >
                A
              </span>
            </span>
          </div>
        </div>
        <div className='mx-auto mb-auto w-[243px]'>
          <svg
            className='mx-auto w-full'
            xmlns='http://www.w3.org/2000/svg'
            height={4}
            viewBox='0 0 259 2'
            fill='none'
          >
            <path d='M0 1H259' stroke='url(#paint0_linear_776_24814)' />
            <defs>
              <linearGradient
                id='paint0_linear_776_24814'
                x1={0}
                y1='1.5'
                x2={259}
                y2='1.5'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#936321' stopOpacity={0} />
                <stop offset='0.505' stopColor='#EBCA76' />
                <stop offset={1} stopColor='#936321' stopOpacity={0} />
              </linearGradient>
            </defs>
          </svg>

          <div className='text-10 -my-0.5 mx-auto flex w-full items-center justify-center py-1 text-white'>
            <div className='flex grow basis-0 justify-end text-right'>
              <span
                className='grow basis-0 text-center'
                style={{
                  color: '#00A958',
                }}
              >
                {cardItem?.hdp?.hTeam?.odds}
              </span>
              <span className='grow basis-0 text-center'>
                {cardItem?.hdp?.hTeam?.rate}
              </span>
              <span
                className='grow basis-0 text-center'
                style={{
                  color: '#A92900',
                }}
              >
                {cardItem?.hdp?.aTeam?.odds}
              </span>
            </div>
            <div className='mx-5'></div>
            <div className='flex grow basis-0 justify-end text-left'>
              <span
                className='grow basis-0 text-center'
                style={{
                  color: '#00A958',
                }}
              >
                {cardItem?.ou?.aTeam?.odds}
              </span>
              <span className='flex-1 text-center'>
                {cardItem?.ou?.hTeam?.rate}
              </span>
              <span
                style={{
                  color: '#A92900',
                }}
                className='grow basis-0 text-center'
              >
                {cardItem?.ou?.aTeam?.odds}
              </span>
            </div>
          </div>

          <svg
            className='mx-auto w-full'
            xmlns='http://www.w3.org/2000/svg'
            height={4}
            viewBox='0 0 259 2'
            fill='none'
          >
            <path d='M0 1H259' stroke='url(#paint0_linear_776_24814)' />
            <defs>
              <linearGradient
                id='paint0_linear_776_24814'
                x1={0}
                y1='1.5'
                x2={259}
                y2='1.5'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#936321' stopOpacity={0} />
                <stop offset='0.505' stopColor='#EBCA76' />
                <stop offset={1} stopColor='#936321' stopOpacity={0} />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className='mx-auto mb-2 mt-3 flex w-full justify-center'>
          <div className=''>
            <div
              onClick={() =>
                openGameProvider(
                  {
                    ...cardItem,
                    isHotMatch: true,
                    partner_provider: PARTNER_PROVIDER.ksport,
                    is_new_tab: false,
                  },
                  router
                )
              }
              className='box cursor-hand hover-shine anim-bg-linear-1 bg-linear-1 text-12 text-main-secondary animation-button w-[70px] rounded-[5px] py-1 text-center font-semibold'
            >
              K-sport
            </div>
          </div>
          <div className=''>
            <div
              onClick={() =>
                openGameProvider(
                  {
                    ...cardItem,
                    isHotMatch: true,
                    partner_provider: PARTNER_PROVIDER.athena,
                  },
                  router
                )
              }
              className='box cursor-hand hover-shine anim-bg-linear-1 bg-linear-1 text-12 text-main-secondary animation-button mx-2 w-[70px] rounded-[5px] py-1 text-center font-semibold'
            >
              SABA
            </div>
          </div>
          <div className=''>
            <div
              onClick={() =>
                openGameProvider(
                  {
                    ...cardItem,
                    isHotMatch: true,
                    partner_provider: PARTNER_PROVIDER.bti,
                  },
                  router
                )
              }
              className='box cursor-hand hover-shine anim-bg-linear-1 bg-linear-1 text-12 text-main-secondary animation-button w-[70px] rounded-[5px] py-1 text-center font-semibold'
            >
              BTI
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchCard;
