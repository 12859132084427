import { useEffect, useRef } from 'react';

type IntersectionObserverHook = (
  ref: React.RefObject<Element>,
  callback: (entry: IntersectionObserverEntry) => void,
  options?: IntersectionObserverInit
) => void;

const useIntersectionObserver: IntersectionObserverHook = (
  ref,
  callback,
  options = {}
) => {
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (!ref.current) return;

    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        callback(entry);
      });
    }, options);

    observerRef.current.observe(ref.current);

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [ref, options, callback]);
};

export default useIntersectionObserver;
