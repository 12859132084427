/* eslint-disable @typescript-eslint/ban-ts-comment */
import { getCookie } from 'cookies-next';
import { createInstance } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next/initReactI18next';

import { getOptions } from './settings';

export const initI18next = async (lng: any, ns: any) => {
  const i18nInstance = createInstance();
  await i18nInstance
    .use(Backend)
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(
      resourcesToBackend((language: any, namespace: any) => {
        return import(`./locales/${language}/${namespace}.json`);
      })
    )
    .init({
      ...getOptions(lng, ns),
    });
  return i18nInstance;
};

export async function useTranslation(
  lng: string | readonly string[] | null | any,
  ns?: any[] | string,
  options: any = {}
) {
  const lngCookie = getCookie('i18next');
  const i18nextInstance = await initI18next(lng || lngCookie || 'en', ns);

  return {
    t: i18nextInstance.getFixedT(
      lng || lngCookie || 'en',
      Array.isArray(ns) ? ns[0] : ns,
      options.keyPrefix
    ),
    i18n: i18nextInstance,
  };
}
