'use client';

import { getCookie } from 'cookies-next';
import { Url } from 'next/dist/shared/lib/router/router';
import { useParams } from 'next/navigation';
import { UseTranslationOptions } from 'react-i18next';

import { useTranslation } from '@/app/i18n/client';
import { fallbackLng } from '@/app/i18n/settings';
const requiredLoginLinks = [/.*\/account\/.*/, /.*\/user\/.*/];

const useClientTranslation = (
  ns: any,
  options?: UseTranslationOptions<undefined>
) => {
  const params = useParams();
  const token = getCookie('token');
  const { lng } = (params || {}) as any;
  const { t } = useTranslation(lng as string, ns, options);
  const lngPrefix = lng === fallbackLng ? '' : `/${lng}`;
  const isEngLng = lng === 'en';
  const currentLink = (href: Url = '/') => {
    const url = `${lngPrefix}${href}`;
    if (typeof href === 'string') {
      if (!token && requiredLoginLinks.some((_) => _.test(href))) {
        return `${lngPrefix}?popup=login`;
      }

      if (isEngLng && href.includes('/en')) {
        return url.replace('/en', '') || url;
      }

      return url;
    }
    return href;
  };

  return { t, lng, currentLink };
};

export default useClientTranslation;
// const i18nCheck = i18n.languages.toLowerCase().includes('en') ||
// i18n.languages.toLowerCase().includes('en-');
