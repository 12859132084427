import clsx from 'clsx';
import React, { ComponentProps } from 'react';

const PlaceHolderGameLoading: React.FC<
  { amount?: number } & ComponentProps<'div'>
> = ({ amount = 1, className = '', ...props }) => {
  const totalItems = new Array(amount).fill('');
  return (
    <>
      {totalItems.map((_, index) => (
        <div key={index} {...props}>
          <div
            className={clsx(
              'loading-bg cursor-hand shadow-box-light mx-1 flex aspect-[3/2.5] flex-col rounded-lg',
              className
            )}
          />
        </div>
      ))}
    </>
  );
};

export default PlaceHolderGameLoading;
