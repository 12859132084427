import { PARTNER_PROVIDER, PARTNER_PROVIDER_OPTIONS } from '@/constant/data';

export const liveCasinoLobby = [
  {
    id: 'lobby_2',
    key: 'lobby_2',
    icon: '/img/menu/live-casino/banner_1.png',
    image: '/img/game/live-casino/lobby/viacasino.png',
    img: '/img/game/live-casino/lobby/viacasino.png',
    partner_provider: PARTNER_PROVIDER.viacasino,
    is_require_login: true,
    name: `${PARTNER_PROVIDER_OPTIONS[PARTNER_PROVIDER.viacasino].label} Lobby`,
  },
  {
    id: 'lobby_3',
    key: 'lobby_3',
    icon: '/img/menu/live-casino/banner_3.png',
    image: '/img/game/live-casino/lobby/sagaming.png',
    img: '/img/game/live-casino/lobby/sagaming.png',
    partner_provider: PARTNER_PROVIDER.sagaming,
    is_require_login: true,
    name: `${PARTNER_PROVIDER_OPTIONS[PARTNER_PROVIDER.sagaming].label} Lobby`,
  },
  {
    id: 'lobby_4',
    key: 'lobby_4',
    icon: '/img/menu/live-casino/banner_4.png',
    image: '/img/game/live-casino/lobby/pragmatic.png',
    img: '/img/game/live-casino/lobby/pragmatic.png',
    partner_provider: PARTNER_PROVIDER.pragmatic,
    partner_game_id: '104',
    is_require_login: true,
    name: `${PARTNER_PROVIDER_OPTIONS[PARTNER_PROVIDER.pragmatic].label} Lobby`,
  },
  {
    id: 'lobby_5',
    key: 'lobby_5',
    icon: '/img/menu/live-casino/banner_5.png',
    image: '/img/game/live-casino/lobby/ezugi.png',
    img: '/img/game/live-casino/lobby/ezugi.png',
    partner_provider: PARTNER_PROVIDER.ezugi,
    is_require_login: true,
    name: `${PARTNER_PROVIDER_OPTIONS[PARTNER_PROVIDER.ezugi].label} Lobby`,
  },
  {
    id: 'lobby_6',
    key: 'lobby_6',
    icon: '/img/menu/live-casino/banner_7.png',
    image: '/img/game/live-casino/lobby/vivo.png',
    img: '/img/game/live-casino/lobby/vivo.png',
    partner_provider: PARTNER_PROVIDER.vivo_multi,
    is_require_login: true,
    name: `${
      PARTNER_PROVIDER_OPTIONS[PARTNER_PROVIDER.vivo_multi].label
    } Lobby`,
  },
  {
    id: 'lobby_7',
    key: 'lobby_7',
    icon: '/img/menu/live-casino/banner_8.png',
    image: '/img/game/live-casino/lobby/evolution.png',
    img: '/img/game/live-casino/lobby/evolution.png',
    partner_provider: PARTNER_PROVIDER.evo_multi,
    is_require_login: true,
    name: `${PARTNER_PROVIDER_OPTIONS[PARTNER_PROVIDER.evo_multi].label} Lobby`,
  },
  {
    id: 'lobby_8',
    key: 'lobby_8',
    icon: '/img/menu/live-casino/banner_9.png',
    image: '/img/game/live-casino/lobby/microgaming.png',
    img: '/img/game/live-casino/lobby/microgaming.png',
    partner_provider: PARTNER_PROVIDER.microgaming,
    partner_game_id: 'SMG_titaniumLiveGames_MP_Baccarat',
    is_require_login: true,
    name: `${
      PARTNER_PROVIDER_OPTIONS[PARTNER_PROVIDER.microgaming].label
    } Lobby`,
  },
  {
    id: 'lobby_92',
    key: 'lobby_92',
    icon: '/img/menu/live-casino/banner_dreamgaming.png',
    image: '/img/game/live-casino/lobby/dreamgaming.png',
    img: '/img/game/live-casino/lobby/dreamgaming.png',
    partner_provider: PARTNER_PROVIDER.dreamgaming,
    is_require_login: true,
    name: `${
      PARTNER_PROVIDER_OPTIONS[PARTNER_PROVIDER.dreamgaming].label
    } Lobby`,
  },
];
