'use client';

import clsx from 'clsx';
import { AnimatePresence, motion, usePresence } from 'framer-motion';
import { useEffect, useMemo } from 'react';

import {
  TransitionAnimation,
  TransitionAnimationConfig,
} from './transitionConfig';

export default function AnimatedTransition({
  className,
  animation = TransitionAnimation.FADE,
  onAnimationStart,
  onAnimationComplete,
  children,
  isInitial = true,
  ...restProps
}: any) {
  const [isPresent, safeToRemove] = usePresence();

  const animationProps = useMemo(() => {
    if (!animation) return {};
    const config = TransitionAnimationConfig[animation];
    const initial = config?.initial;
    const exit = config?.exit ?? config?.initial;
    return {
      ...config,
      initial,
      exit,
    };
  }, [animation]);

  const onTransitionStart = () => {
    onAnimationStart && onAnimationStart();
  };
  const onTransitionComplete = () => {
    onAnimationComplete && onAnimationComplete();
  };

  useEffect(() => {
    if (!isPresent) {
      setTimeout(safeToRemove, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresent]);

  return (
    // <div className='relative'>
    <AnimatePresence initial={isInitial}>
      <motion.div
        {...animationProps}
        {...restProps}
        className={clsx('will-change-auto', className)}
        onAnimationStart={onTransitionStart}
        onAnimationComplete={onTransitionComplete}
      >
        {children}
      </motion.div>
    </AnimatePresence>
    // </div>
  );
}
