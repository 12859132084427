'use client';
import React from 'react';

import { TransitionAnimation } from '@/components/modules/AnimatedTransition/transitionConfig';
import { Page } from '@/components/modules/Page';
import MatchSlider from '@/components/modules/Slider/MatchSlider';

import { RenderProviderGames } from '@/app/[lng]/(home)/components/Mobile/components/Renderer/RenderProviderGames';
import { esports, sports } from '@/constant/data';

// const mock = [
//   {
//     teams: [
//       {
//         name: 'Brighton',
//         image: 'https://static.j001d.com/img/hot_match/Brighton1.png',
//       },
//       {
//         name: 'Manchester City',
//         image: 'https://static.j001d.com/img/hot_match/Manchester_City1.png',
//       },
//     ],
//     league_name: 'England Premier League',
//     league_name_text: '',
//     event_id: '1663163',
//     league_id: '76',
//     text_time: '2024-04-25T19:00:00Z',
//     type: 'hotmatch',
//     news_url: '',
//     hdp: {
//       hTeam: {
//         rate: '1.00',
//         odds: '-0.92',
//       },
//       aTeam: {
//         rate: '-1.00',
//         odds: '0.8',
//       },
//     },
//     ou: {
//       hTeam: {
//         rate: '3.25',
//         odds: '-0.99',
//       },
//       aTeam: {
//         rate: '-3.25',
//         odds: '0.85',
//       },
//     },
//     position: 1,
//     client_id: 'bc119193',
//     score: [null, null],
//     ext_score: [null, null],
//     _id: '66220d8b50638a035f47293c',
//     id: 56340,
//     created_time: '2024-04-19T06:22:03.136Z',
//     last_updated_time: '2024-04-19T06:22:03.136Z',
//     error: false,
//   },
// ];

const SportGames: React.FC<any> = (props) => {
  const { topMatch } = props;

  return (
    <Page
      className='vw:w-[100%] mx-auto pb-28 pt-3'
      animation={TransitionAnimation.SLIDE_UP_2}
    >
      <h2 className='vw:mb-3 vw:pl-5 flex items-center'>
        <img
          className='vw:w[19px] vw:h-[19px]'
          src='/img/game/sport/ball.png'
          alt=''
        />
        <span className='vw:text-16 vw:ml-1 font-bold'>Sportbook</span>
      </h2>
      {topMatch?.length > 0 && (
        <>
          <div className='hide-scroll-bar w-full'>
            <MatchSlider data={topMatch} />
          </div>
        </>
      )}
      {RenderProviderGames(
        sports,
        null,
        'w-full vw:!px-4 overflow-x-scroll vw:!gap-2 ',
        'vw:min-w-[116px] vw:max-w-[116px] w-full'
      )}

      <h2 className='vw:mb-2 vw:mt-4 vw:pl-4 flex items-center'>
        <img
          className='vw:w[19px] vw:h-[19px]'
          src='/img/game/sport/ball.png'
          alt=''
        />
        <span className='vw:text-16 vw:ml-1 font-bold'>Virtual Sport</span>
      </h2>
      {RenderProviderGames(
        esports.filter((i: any) => i.isVirtual),
        null,
        'w-full vw:!px-4 overflow-x-scroll vw:!gap-2 ',
        'vw:min-w-[116px] vw:max-w-[116px] w-full'
      )}

      <h2 className='vw:mb-2 vw:mt-4 vw:pl-4 flex items-center'>
        <img
          className='vw:w-[19px] vw:h-[19px]'
          src='/img/game/sport/play.png'
          alt=''
        />
        <span className='vw:text-16 vw:ml-1 font-bold'>Esport</span>
      </h2>
      {RenderProviderGames(
        esports.filter((i: any) => !i.isVirtual),
        null,
        'w-full vw:!px-4 overflow-x-scroll !gap-2 ',
        'vw:min-w-[116px] vw:max-w-[116px] w-full'
      )}
    </Page>
  );
};

export default SportGames;
